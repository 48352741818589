/* tslint:disable:no-any */
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from "@angular/cdk/drag-drop";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { Leads } from "src/app/types/leads";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { AuthService } from "src/app/services/auth";
import { ModalDirective } from "ngx-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-kanban",
  templateUrl: "./kanban.component.html",
  styleUrls: ["./kanban.component.scss"]
})
export class KanbanComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public list: listParams[];
  @Input() public leadStatusTemplate: any;
  @Input() public editTaskTemplate: any;
  @Input() public StatusTemplate: any;
  @Input() public statusType: any;
  @Input() public activeTab: number;
  @Output() public action = new EventEmitter();
  @Output() public taskDone = new EventEmitter();
  @Output() public editStagesRefresh = new EventEmitter();
  @Output() public fetchCurrentLeadsForStage = new EventEmitter();
  @Output() public onSelectedStageChange = new EventEmitter();
  @Output() public archiveConfirmModal = new EventEmitter();
  @Output() public archiveStageData = new EventEmitter();
  @ViewChild("leadsPopover") public leadsPopover: any;
  @ViewChild("tasksPopover") public tasksPopover: any;
  @ViewChild("StatusPopover") public StatusPopover: any;
  @ViewChild("ArchivePopover") public ArchivePopover: any;
  @ViewChild("editStage") public newStageModal?: ModalDirective;
  @ViewChild("selectNewStage") public selectNewStageModal?: ModalDirective;
  public confirmModal = false;
  public editStageModal = false;
  public stageId: string;
  public isAdmin = false;
  public editStageForm: FormGroup;
  public disableFields: boolean;
  public kanbanListEditIndex: number;
  public currencyPrefix: string = "$";
  public stageLeadsData: any;
  public archiveStageId: string;
  public stageLeads!: any; // Define the input property

  

  constructor(
    private dataService: DataService,
    private router: Router,
    private notifier: NotifierService,
    private fb: FormBuilder,
    public authService: AuthService
  ) {}

  public async ngOnInit() {
    window.addEventListener("click", this.onOutSideClick);
    if (this.authService.isAdmin()) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.currencyPrefix = localStorage.getItem("currencyPrefix") || this.currencyPrefix;
    this.initializeStageForm();
  }

  public ngOnDestroy() {
    window.removeEventListener("click", this.onOutSideClick);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.statusType &&
      changes.statusType.currentValue === ""
    ) {
      this.list = this.list.filter(x => {
        x.data.filter(y => {
          y.moreActions = false;
          return y;
        });
        return x;
      });
    }
  }

  public getTotalDealValue(data: []) {
    let totalDealValue = 0;
    data.forEach((x: any) => {
      if (x.dealValue) {
        totalDealValue += x.dealValue;
      }
    });
    return totalDealValue;
  }
  public drop(evt: CdkDragDrop<string[]>) {
    const container: any = evt.container.data;
    const previousContainer: any = evt.previousContainer.data;
    if (evt.previousContainer !== evt.container) {
      this.action.emit({
        type: "stage",
        lead: previousContainer.data[evt.previousIndex],
        index: container.order
      });
      transferArrayItem(
        previousContainer.data,
        container.data,
        evt.previousIndex,
        evt.currentIndex
      );
    } else {
      moveItemInArray(container.data, evt.previousIndex, evt.currentIndex);
    }
  }

  public onOutSideClick = (e: any) => {
    if (!e.target.classList.contains("archive-link")) {
      this.list = this.list.filter(x => {
        x.data.filter(y => {
          y.moreActions = false;
          return y;
        });
        return x;
      });
    }
  };

  public onClick(type: string, lead: Leads, popoverTemplateRef: any) {
    this.action.emit({ type, lead, popoverTemplateRef});
  }

  archiveLead(obj:any){
let khanIndex  = this.list.findIndex(x => x.id === obj.stage.objectId);
let khanLeadIndex  = this.list[khanIndex].data.findIndex(x => x.objectId === obj.objectId);
this.list[khanIndex].data.splice(khanLeadIndex, 1)
  }

  public async deleteStage(type: string) {
    try {
      await this.dataService.deleteOnServer(
        "classes/Stages/" + this.stageId,
        {}
      );
      this.stageId = "";
      this.action.emit({ type });
      this.confirmModal = false;
      this.notifier.notify("success", "Stage Deleted");
    } catch (e) {
      console.error("error", e.error);
      this.notifier.notify("error", e.error.error);
    }
  }
  public async showActions(i: number, j: number) {
    const archive = this.list[i].data[j].moreActions || false;
    this.list = this.list.filter(x => {
      x.data.filter(y => {
        y.moreActions = false;
        return y;
      });
      return x;
    });
    setTimeout(() => {
      this.list[i].data[j].moreActions = !archive;
    }, 5);
  }

  public async showSpecificContact(contactID: string) {
    this.router.navigate(["/contact-details"], {
      state: { contactObjectId: contactID, isView: true }
    });
  }

  public navigateToLeadDetails(leadId: string, spaceId : string) {
    this.router.navigate(['/lead-details', { leadId, spaceId}]);
  }

  public navigateToOutreach(leadId: string, contactId: string) {
    this.router.navigate(['/outreach', { leadId, contactId, fromLeads: true }]);
  }
  
  public async updateStage() {

    if (this.stageId) {
      try {
        const data = {
          name: this.editStageForm.get('name')!.value,
          order: this.editStageForm.get('order')!.value,
          probabilityValue: this.editStageForm.get('probability')!.value,
        };
        await this.dataService.updateToServer(
            `classes/Stages/${this.stageId}`,
            data,
        );

        this.notifier.notify('success', 'Stage Updated Successfully!!');
        this.list[this.kanbanListEditIndex].name = data.name
        this.list[this.kanbanListEditIndex].order = data.order
        this.list[this.kanbanListEditIndex].probabilityValue = data.probabilityValue
        this.editStagesRefresh.emit();
      } catch (e) {
        this.notifier.notify('error', e.error.error);
      }
    }
    this.newStageModal!.hide();
  }

  private initializeStageForm() {
    this.editStageForm = this.fb.group({
      name: ['', Validators.required],
      probability: ['', Validators.required],
      order: ['', Validators.required],
    });
  }

  public async onTaskChange(checked: boolean, item: any) {

    if (checked) {
      const taskParams = {
        isComplete: checked,
      };
      await this.dataService.updateToServer(
        'classes/Tasks/' + item.nextTask.objectId,
        taskParams,
      );
      this.notifier.notify('success', 'Task Closed Successfully!!');
      // const leadObjectId = this.currentLead;
      setTimeout(async () => {
  
        this.taskDone.emit();
      }, 100);
    }
    // this.isPageLoading = false;
  }

  public editStages(event: Event, currentStage: any, index:number) {
    this.kanbanListEditIndex = index
    if (event) {
      event.preventDefault();
    }
    this.stageId = currentStage.id;
    this.initializeStageForm();
    this.editStageForm.patchValue({
      name: currentStage.name || '',
      probability: currentStage.probabilityValue || 100,
      order: currentStage.order || '0',
    });
    this.newStageModal!.show();
  }

  //Archive Stage
  public async archiveStage(){
    await this.getCurrentLeadsForstage();
    this.archiveStageId = this.stageId;
    this.confirmModal = false;
    if(this.stageLeads.length != 0){
      this.selectNewStageModal!.show();
    }else{
      // this.archiveSelectedStage();
      this.archiveConfirmModal.emit();
    }
  }

  public archiveSelectedStage(){
    try{
       this.dataService.updateToServer('classes/Stages/' + this.stageId, {
        isArchived: true,
      });
      this.editStagesRefresh.emit();
      this.notifier.notify("success", 'Stage deleted successfully !!');
    }catch(e){
      console.log(e)
    }
  }

  onSelectStageChange(event:any){
    this.onSelectedStageChange.emit(event.target.value);
  }

 
  public async getCurrentLeadsForstage() {
    const leadParams = {
      where: {
        stage: {
          __type: 'Pointer',
          className: 'Stages',
          objectId: this.stageId,
        },
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        isArchived: { $ne: true },
      },
    };
    const lead = await this.dataService.getFromServer(
      'classes/Leads/',
      leadParams,
    );
    this.stageLeads = lead.results || [];
    //this.stageLeadsToKanban = this.currentStageLeads;
    this.archiveStageData.emit(this.stageId);
    this.fetchCurrentLeadsForStage.emit(this.stageLeads);
  }

  public archiveConfirm(){
    this.archiveConfirmModal.emit();
    this.selectNewStageModal!.hide();
  }

  

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      "has-error": this.isFieldValid(field, currentForm),
      "has-feedback": this.isFieldValid(field, currentForm)
    };
  }
}



type listParams = {
  name: string;
  id: string;
  probabilityValue:number;
  order:number;
  spaceId: string;
  data: [
    {
      name: string;
      moreActions: boolean;
      objectId:string;
      isArchived:boolean;
    }
  ];
};
