import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import {UserService} from '../../services/user.service';
import { AuthService } from 'src/app/services/auth';
import * as Parse from 'parse';
import { environment } from '../../../environments/environment';
import { HelperService } from '../../services/helper.service';
import { NotifierService } from 'angular-notifier';
import { unset } from '@inleads/event-logger';

// @ts-ignore
Parse.initialize(environment.appId, environment.javaScriptKey);
//(Parse as any).serverURL = 'https://server.inleads.ai/parse';
(Parse as any).serverURL = environment.apiUrl;

@Component({
  selector: 'aside',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() public isLoggedIn: boolean;
  @Input() public routeParams: routeParams;
  @Input() public isSidebarOpened: boolean;
  @Output() public sideBarToggle: EventEmitter<null> = new EventEmitter();
  public isDesktopMenu: boolean;
  public isMobileMenu: boolean;
  public isSpacesExpanded: any;
  public isAdmin: boolean;
  public isManager: boolean;
  public isSuperAdmin: boolean;
  public spacesList: any[];
  public spaceId : string;
  // public showSub: boolean = false;
  public showSub: boolean;
  public collapseSubMenu:boolean = true
  public expandSubMenu:boolean = false
  public iconColor = ['#40bc86', '#1bbc9b', '#27ae61', '#00d717', '#f31d2f', '#eb555c', '#fcb410', '#b17e22', '#f14d16']

   // Define submenu items for Contacts menu
  //  public contactSubmenus: { label: string, routerLink: string }[] = [
  //   { label: 'Contacts', routerLink: '/contacts' },
  //   { label: 'Groups', routerLink: '/groups' }
  // ];

  constructor(
    private dataService: DataService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public userService: UserService,
    public authService: AuthService,
    public helperService: HelperService,
    private notifier: NotifierService,
  ) {}

  public ngOnInit() {
    // set user context for js sdk
    const localUser = sessionStorage.getItem('user') || localStorage.getItem('user') || '{}';
    const user = JSON.parse(localUser);
    if (user && user.sessionToken) {
      Parse.User.become(user.sessionToken);
    }
    if (this.deviceService.isMobile()) {
      this.isDesktopMenu = false;
      this.isMobileMenu = true;
    } else {
      this.isDesktopMenu = true;
      this.isMobileMenu = false;
      this.showSub = true;
    }
    // @ts-ignore
    this.isAdmin = this.authService.isAdmin();
    this.isManager = this.authService.isManager();
    this.isSuperAdmin = this.authService.isSuperAdmin();
    this.isSpacesExpanded = true;
    this.liveServerUpdates();
    this.getSpaces();
      setTimeout(() => {
        this.helperService.getLiveQueryDataSpaces().subscribe(() => {
          this.getSpaces();
        });
      }, 5000)
  }

  public toggleExpander(){
    this.isSpacesExpanded = !this.isSpacesExpanded;
  }

  // Add this method to toggle the visibility of submenus under Contacts
  // public toggleContactSubmenus() {
  //   this.showSub = !this.showSub;
  // }

  // Method to get the appropriate icon class based on the submenu label
  // public getIconClass(submenuLabel: string): string {
  //   switch (submenuLabel) {
  //     case 'Contacts':
  //       return 'common requests-icon';
  //     case 'Groups':
  //       return 'common customers-icon';
  //     default:
  //       return 'common requests-icon';
  //   }
  // }
  public showSubMenu()
  {
    this.collapseSubMenu = !this.collapseSubMenu;
    this.expandSubMenu = !this.expandSubMenu
  }

  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId },
        isArchived: { $ne : true },
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Spaces', spacesQuery,
    );
    // this.spacesList = resp.results;
    this.spaceId = resp.results && resp.results.length > 0 ? resp.results[0].objectId : '';
  }

  public async liveServerUpdates(){
    let self = this;
    let leadsSubscription : any;
    const leadsObj = Parse.Object.extend('Leads');
    const leadsQuery = new Parse.Query(leadsObj);
    leadsQuery.equalTo('entity', {__type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId});
    if (leadsSubscription) leadsSubscription.unsubscribe();
    leadsSubscription = await leadsQuery.subscribe();
    leadsSubscription.on('create', async function(lead: any) {
        console.log('New lead Recieved'+lead.id);
      self.notifier.notify('success', 'New Lead Created !!!');
      self.helperService.setLiveQueryLeadsData(lead);
      self.helperService.setLiveQueryNotificationsData(lead);
    });
    leadsSubscription.on('update', async function(lead: any) {
      console.log('Lead Updated ::: '+lead.id);
      self.helperService.setLiveQueryLeadsData(lead);
  });
  }

  public toggleNavigation() {
    this.isSidebarOpened = !this.isSidebarOpened;
    this.sideBarToggle.emit();
  }

  public async logoutUser() {
    const userDetails = {};
    await this.dataService.postToServer('/logout', userDetails);
    this.helperService.cleanupLeadsLiveQueries();
    unset();
    await this.dataService.cleanHeaders();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('');
  }
  public navigateTo(path: string) {
    this.router.navigate([path]);
    if (this.deviceService.isMobile()) {
      this.toggleNavigation();
    }
  }
}

type routeParams = {
  currentRoute: string;
};
