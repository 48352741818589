import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatatableSettings} from '../../types/datatables';
import {DataService} from '../../services/data.service';
import {UserService} from '../../services/user.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotifierService} from 'angular-notifier';
import {DataTableDirective} from 'angular-datatables';
import { AuthService } from 'src/app/services/auth';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';
import { validationPattern } from '../../constants/regexp';
import { Products } from 'src/app/types/products';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LeadParams } from '../../types/leads';
import { User } from '../../types/user';
import { LeadsService } from '../../services/leads.service';
import { track } from '@inleads/event-logger';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.scss']
})
export class VisitorsComponent implements OnInit {

  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
  public dtOptions: DatatableSettings = {};
  public dtInstance: DataTables.Api;
  public modalRef: BsModalRef;
  public isLoading: boolean;
  public currentVisitor: string;
  public deleteModal = false;
  public eventsData: any;
  public isViewMode: boolean;
  public disableFields: boolean;
  public productID: any;
  public productList: any[] = [];
  public spacesList: any[];
  public selectedSpace: any;
  public selected: any;
  public minDate: any;
  public stagesList: any[];
  public newLeadForm : FormGroup;
  public newProductForm: FormGroup;
  public currentUser: User | undefined;
  public contactObjectId: any;
  @ViewChild('newlead') public newleadModal?: ModalDirective;
  @ViewChild('newProduct') public newProductModal?: ModalDirective;
  constructor(private dataService: DataService, private router: Router, public userService: UserService, private notifier: NotifierService,public authService: AuthService, private fb: FormBuilder, private leadsService: LeadsService,) {
  }

  public ngOnInit() {
    this.loadVisitors();
    this.intializeLeadForm();
    this.getSpaces();
    this.getProducts();
    this.currentUser = this.userService.getUser();
  }

  public async loadVisitors() {
    this.isLoading = true;
    let activityParams = {
      include: 'contact',
      order: '-createdAt',
      limit: 1000,
      where: {
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        name: 'VISIT',
      }, 
    };
    const events = await this.dataService.getFromServer(
      'classes/Events',
      activityParams,
    );
    const uniqueDomainsMap = new Map<string, any>();
    events.results.forEach((event: any) => {
      if (event.domain) {
        uniqueDomainsMap.set(event.domain, event);
      }
    });
    this.eventsData = Array.from(uniqueDomainsMap.values());
    this.eventsData.sort((a: any, b: any) => {
      return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
    });
    this.isLoading = false;

    this.dtOptions = {
      dom: '<"appRequestsFilter" lf>tipr',
      columnDefs: [
        {
          orderable: false,
          targets: 0,
          responsivePriority: 1,
        },
        {
          responsivePriority: 1,
          targets: 3, // createdAt
        },
        {
          responsivePriority: 0,
          targets: 1, // company
        },
        {
          responsivePriority: 1,
          targets: 2, // domain
        },
        {
          responsivePriority: 2,
          targets: 6, // industry
        },
        {
          responsivePriority: 2,
          targets: 7, // estimatedAnnualRevenue
        },
        {
          responsivePriority: 1,
          targets: 4, // city / country
        },
        {
          responsivePriority: 2,
          targets: 5, // contact.email
        },
        {
          responsivePriority: 3,
          targets: 8, // ipAddress
        },
        {
          responsivePriority: 1,
          targets: 9, // actions
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: 'Show _MENU_',
      },
      responsive: true,
      data: this.eventsData,
      columns: [
        {data: ''},
        {
          data: (row: any) => {
            return row.company ? row.company : 'NA';
          },
        },
        {
          data: (row: any) => {
            return row.domain
            ? `${row.domain} <a href="https://www.${row.domain}" target="_blank"  style="margin-left: 10px;" title="Preview Domain">
                  <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" class='fa-domain' viewBox="0 0 512 512"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z"/></svg>
              </a>`
            : 'NA';
          },
        },
        {
          data: (row: any) => {
            return row.createdAt ? this.formatDate(row.createdAt)  : 'NA';
          },
          orderable: true,
        },
        {
          data: (row: any) => {
            return row.city && row.country ? `${row.city} / ${row.country}` : (row.city || row.country || 'NA');
          },
        }, 
        {
          data: (row: any) => {
            return row.email || 'NA';              
          },
        }, 
        {
          data: (row: any) => {
            return row.industry ? row.industry : 'NA';
          },
        },
        {
          data: (row: any) => {
            return row.estimatedAnnualRevenue ? row.estimatedAnnualRevenue : 'NA';
          },
        },                            
        {
          data: (row: any) => {
            return row.ipAddress ? row.ipAddress : 'NA';
          },
        },
        {
          data: () => '<span class="fa fa-trash" title="Delete"></span>' + '<span class="fa fa-plus cpointer" style="margin-left: 10px" title="Add lead"></span>',
        },    
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => { });
        row.addEventListener('click', e => {
          const isDomainPreviewClick = (e.target as HTMLElement).closest('a[target="_blank"]');
          if (isDomainPreviewClick) {
            e.stopPropagation();
            return;
          }
          let viewMode = true; 
         if ((e.target as HTMLElement).classList.contains('fa-trash')) {
            this.deleteVisitor((data as any).objectId);
            viewMode = false;
            return;
          }
          if ((e.target as HTMLElement).classList.contains('fa-plus')) {
            this.openNewLeadModal();
          }
          if (!(e.target as HTMLElement).classList.contains('fa-plus') &&
            !(e.target as HTMLElement).classList.contains('dtr-control')) {
          this.router.navigate(['/visitor-details'], {
          state: {
            visitorObjectId: (data as any).objectId,
            visitorData: data,
            isView: viewMode,
                },
            });
          }
        });
        return row;
      },
    };
    if (events && events.length) {
      setTimeout(this.generateFilters, 100);
    }
  }

  private formatDate = (date : any) => {
    const currentDate = moment();
    const dateObj = moment(date);
    const diffInDays = currentDate.diff(dateObj, 'days');

    if (diffInDays === 0) {
      return `${dateObj.fromNow()}`;
    } else if (diffInDays === 1) {
      return `${dateObj.fromNow()}`;
    } else if (diffInDays >= 2 && diffInDays <= 7) {
      return `${dateObj.fromNow()}`;
    } else {
      return dateObj.format('MMMM D, YYYY');
    }
  }

  private generateFilters = async () => {
    if (this.tableWrapperElement) {
      const filter = this.tableWrapperElement.nativeElement.querySelector(
        '.appArticlesFilter',
      );
      this.dtInstance = await this.datatableElement.dtInstance;
      const dtInstance = await this.datatableElement.dtInstance;
      filter.querySelectorAll('a').forEach((ele: HTMLElement) =>
        ele.addEventListener('click', (evt: Event) => {
          evt.preventDefault();
          const type = (evt.target as HTMLInputElement)!.dataset.type;
          dtInstance
            .column(5)
            .search(type!)
            .draw();
        }),
      );
    }
  };

  public async deleteVisitor(visitorId: string) {
    this.deleteModal = true;
    this.currentVisitor = visitorId;
  }

  public async clickYes() {
    const visitorId = this.currentVisitor;
    try {
      await this.dataService.deleteOnServer(
        'classes/Events/' + visitorId,
      );
      this.deleteModal = false;
      this.eventsData = this.eventsData.filter(
        (p: { objectId: string }) => p.objectId !== visitorId,
      );
      await this.loadVisitors().then();
      this.notifier.notify('success', 'Visitor deleted successfully');
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, please try again');
    }
  }

  public decline() {
    this.deleteModal = false;
  }

  private intializeLeadForm(){
    this.newLeadForm = this.fb.group({
      objectId: ['', Validators.required],
      stage: [''],
      space: [''],
      product: [''],
      fullName: ['', Validators.required],
      email: [
        '',
        [Validators.required, Validators.pattern(validationPattern.email)],
      ],
      phone: ['',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(validationPattern.phone),
        ],
      ], 
      dealValue: [''],
      gender: [''],
      city: [''],
      address: [''],
      state: [''],
      pincode: [''],
      description: [''],
      nextActivity: [new Date(), Validators.required],
      activityNotes: [''],
      assignTo: [''],
      creator: [''],
      leadAssignTo: [''],
      contactId: [''],
      closingDate: ['', Validators.required],
      noOfSeats: ['', Validators.required],
      estimatedContractLength: ['', Validators.required],
    });

    this.newProductForm = this.fb.group({
      product_name: ['', Validators.required],
      product_description: ['', Validators.required],
      price_type: ['onetime', Validators.required],
      price_period: ['', Validators.required],
      new_price: [, Validators.required],
      new_sheets: [, Validators.required],
    });
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public async openNewLeadModal(){
    this.newleadModal!.show();
  }

  public addProduct(event: Event) {
    event.preventDefault();
    this.newProductModal!.show();
  }

  public async createProduct(){
    const productName = this.newProductForm.get('product_name')!.value;
    const productDescription = this.newProductForm.get('product_description')!.value;
    const productPriceType = this.newProductForm.get('price_type')!.value;
    const productPricePeriod = this.newProductForm.get('price_period')!.value;
    const productPrice = this.newProductForm.get('new_price')!.value;
    const productNoOfSeats = this.newProductForm.get('new_sheets')!.value;
    if(!productName){
      this.notifier.notify('error', 'Name cannot be empty');
      return
    }
    if(!productDescription){
      this.notifier.notify('error', 'Description cannot be empty');
      return
    }
    // if(!productPriceType){
    //   this.notifier.notify('error', 'Price Type cannot be empty');
    //   return
    // }
    // if(!productPricePeriod){
    //   this.notifier.notify('error', 'Price Period cannot be empty');
    //   return
    // }
    // if(!productPrice){
    //   this.notifier.notify('error', 'Price cannot be empty');
    //   return
    // }
    // if(!productNoOfSeats){
    //   this.notifier.notify('error', 'No Of Seats cannot be empty');
    //   return
    // }
    const productParams: Products = {
      name: productName,
      description: productDescription,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      website: '',
      objectId: '',
      priceType: productPriceType,
      pricePeriod: productPricePeriod,
      price: productPrice,
      noOfSeats: productNoOfSeats,
    };
    const productResponse = await this.dataService.postToServer('classes/Products/', productParams);
    if(productResponse){
      this.notifier.notify('success', 'Product created Successfully!!!');
      this.productID = productResponse.objectId;
      this.newProductForm.reset();
      this.newProductModal!.hide();
    }
    //this.ngxLoader.start();
    this.getProducts();
    // this.initializeLeadForm();
    this.newLeadForm.get('product')!.setValue(productName);
    //this.ngxLoader.stop();
  }

  public async getProducts() {
    let productsQuery;
    if(!this.authService.isSuperAdmin()){
      productsQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      'classes/Products',
      productsQuery,
    );
    this.productList = resp.results;
  }

  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId },
        isArchived: { $ne : true },
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Spaces', spacesQuery,
    );
    this.spacesList = resp.results;
    if( this.spacesList.length > 0){
      this.selectedSpace = this.spacesList[0].objectId;
      this.getStagesList();
    }
  }

  public async getStagesList(){
    const stageQuery = {
      where: {
      space: { __type: 'Pointer', className: 'Spaces', objectId: this.selectedSpace },
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Stages', stageQuery,
    );
    this.stagesList = resp.results;
  }

  isRecurringPrice(): boolean {
    const productId = this.newLeadForm.get('product')!.value;
    if(productId && this.productList){
      const product = this.productList.find(product => product.objectId === productId);
      return product && product.priceType === 'recurring' ? true : false;
    } 
    else{
      return false;
    }
  }

  public async createLead() {
    try {
      const dealValue = parseInt(this.newLeadForm.get('dealValue')!.value);
      const description = this.newLeadForm.get('description')!.value;
      const nextActivity = this.newLeadForm.get('nextActivity')!.value;
      const activityNotes = this.newLeadForm.get('activityNotes')!.value;
      let assignTo = '';
      const leadAssignTo = this.newLeadForm.get('leadAssignTo')!.value;
      const stageObjectId = this.newLeadForm.get('stage')!.value;
      const closingDate = this.newLeadForm.get('closingDate')!.value;
      let productId = this.newLeadForm.get('product')!.value;
      let noOfSeats = this.newLeadForm.get('noOfSeats')!.value;
      let estimatedContractLength = this.newLeadForm.get('estimatedContractLength')!.value;
      const category = 'Lead';

      if (this.productID) {
        productId = this.productID;
      }
      // if (!dealValue) {
      //   return this.notifier.notify('error', 'Please Enter Valid Deal Value');
      // }
      if (!closingDate) {
        return this.notifier.notify('error', 'Please Enter Valid Closing Date');
      }
      if (!stageObjectId) {
        return this.notifier.notify('error', 'Please Select Stage');
      }   
      if (!description) {
        return this.notifier.notify('error', 'Please Enter Description');
      }
      if(this.isRecurringPrice()){
        if (!noOfSeats) {
          return this.notifier.notify('error', 'Please Enter Number of Subscriptions');
        }
      }
      if(this.isRecurringPrice()){
        if (!estimatedContractLength) {
          return this.notifier.notify('error', 'Please Enter Estimated Contract Length');
        }
      }
      if (this.authService.isAgent()) {
        assignTo = this.authService.getUser().objectId;
      } else {
        assignTo = this.newLeadForm.get('assignTo')!.value;
      }
      this.newleadModal!.hide();
      const leadParams: LeadParams = {
        dealValue,
        description,
        stage: {
          __type: 'Pointer',
          className: 'Stages',
          objectId: stageObjectId,
        },
        product: {
          __type: 'Pointer',
          className: 'Products',
          objectId: productId,
        },
        category,
        assignTo: {
          __type: 'Pointer',
          className: '_User',
          objectId: leadAssignTo ? leadAssignTo : this.currentUser!.objectId,
        },
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: this.contactObjectId,
        },
        activityNotes,
        nextActivity: {
          __type: 'Date',
          iso: new Date(nextActivity),
        },
        creator: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.currentUser!.objectId,
        },
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        closingDate: {
          __type: 'Date',
          iso: new Date(closingDate),
        },
        space:{
          __type: 'Pointer',
          className: 'Spaces',
          objectId: this.selectedSpace,
        }
      };

      if (assignTo) {
        leadParams.agent = {
          __type: 'Pointer',
          className: '_User',
          objectId: assignTo,
        };
      }
      
      if(noOfSeats){
        leadParams.noOfSeats = noOfSeats
      }
      if(estimatedContractLength){
        leadParams.estimatedContractLength = estimatedContractLength
      }
      
      await this.leadsService.addLead(leadParams);
      track("NEW_LEAD");
      await this.dataService.updateToServer('classes/Contacts/' + this.contactObjectId, {
        "leadsCount": { "__op": "Increment", "amount": 1 },
        lastActivity: {
          __type: 'Date',
          iso: new Date(),
        },
      });
      this.router.navigate(['/leads/' + this.selectedSpace]);
    }
    catch (e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  //calculating Expected revenue based on no.of subscriptions and estimated contract length
  public onChangeSubsVal(event: Event, prod: any, form: FormGroup) {
    const subsValue = +(event.target as HTMLInputElement).value;
    const eclValue = form.get('estimatedContractLength')!.value;
    this.calcExpRevenue(prod, form, subsValue, eclValue ? eclValue : 1 );
  }

  public onChangeEclVal(event: Event, prod: any, form: FormGroup) {
    const eclValue = +(event.target as HTMLInputElement).value;
    const subsValue = form.get('noOfSeats')!.value;
    this.calcExpRevenue(prod, form, subsValue ? subsValue : 1, eclValue);
  }

  public calcExpRevenue(prod: any, form: FormGroup, subsValue:any, eclValue:any) {
    const selectedProduct = this.productList.find((product) => product.objectId === prod);
    let factor = 1;
    if(selectedProduct.pricePeriod === 'monthly'){
      factor = 12;
    } else if(selectedProduct.pricePeriod === 'weekly'){
      factor = 52;
    } else if(selectedProduct.pricePeriod === 'quarterly'){
      factor = 4;
    } else if(selectedProduct.pricePeriod === 'halfyearly'){
      factor = 2;
    }
    const finalExpRevenue = (subsValue * eclValue * selectedProduct.price * factor);
    form.get('dealValue')!.patchValue(finalExpRevenue);
  }

}
