import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, HostListener, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal'
import { AuthService } from '../../services/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Reports} from 'src/app/types/report';
import { DataTableDirective } from 'angular-datatables';
import { Leads } from 'src/app/types/leads';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/services/data.service';
import { Status } from 'src/app/types/status';
import { Stages } from 'src/app/types/stages';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Contacts } from '../../types/contacts';
import { UserService } from 'src/app/services/user.service';
import { SharedDataService } from '../../services/shared-data.service';
import  jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { track } from '@inleads/event-logger';
import {convertData, getDatesRange, getEventnames} from "../../helpers/helper.date";
import {CompanyOverViewReportService} from "../../services/company-over-view-report.service";
import {EventsService} from "../../services/events.service";
import { MatSelectChange } from '@angular/material/select';

interface ChartData {
    title: string;
    type: string;
    data: any[];
    columnNames: string[];
    options: any;
    width: number;
    height: number;
}

const chartColors: { [key: string]: string } = {
    'Leads': '#2C7BE5',
    'Contacts': '#714d3f',
    'Dau': '#8e5ea2',
    'Revenue':'#6f9654',
    'NewSignUp':'#e6693e',
    'Events':'#f4a261', //'#f4a261', '#2a9d8f'
};

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements  OnInit, AfterViewInit {
    @ViewChild('chartContainer') chartContainer: ElementRef;
    @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
    @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
    @ViewChild('viewReportModal') public viewReportModal: ModalDirective;
    @ViewChild('pdfHeader') pdfHeader: ElementRef;
    @ViewChild('showRecordModal') public showRecordModal: ModalDirective;

    public isLoading: boolean;
    public dtOptions: DataTables.Settings = {};
    public dtElement: DataTableDirective;
    public dtTrigger: Subject<any> = new Subject();
    public dtRendered = false;
    public reportData: Reports[] = [];
    public reportForm: FormGroup;
    public dtInstance: DataTables.Api;
    public leadPageData: Leads[] = [];
    public status: Status[] = [];
    public stages: Stages[] = [];
    public minDate: string;
    public selectedValue: string;
    public selectedRange: string;
    public selectedFilter: string;
    public columnFilters: any;
    public columnFilterValues: any[] = [];
    public showColumnFilterValues: boolean = false;
    public selectedFilterValue: string;
    public showColumnFilter: boolean = true;
    public showAmountInput: boolean = false;
    public amountInputValue: number = 0;
    public reportsList: any;
    public leadsList: boolean;
    public contactsList: boolean;
    public dauList: boolean;
    public signUpList: boolean;
    public revenueList: boolean;
    public totalEvents: boolean;
    public teamList: boolean;
    public archiveModal: boolean;
    public markDealModal: boolean;
    public showCustomRange: boolean;
    public leadId: string;
    public contactId: string;
    public wonStatus: string;
    public lostStatus: string;
    public contactDeactivateModal: boolean;
    public sharedData: any[] = [];
    public slickData: any[] = [];
    public chartDataVal: any[] = [];
    public dauValue: number;
    public churnRate: string;
    public RetentionRate: string;
    public TotalDealAmount: string;
    public TotalLeads: string;
    public TotalContacts: string;
    public chartImage: string;
    chartImageWidth: number;
    chartImageHeight: number;
    public companyOverViewReportData: any;

    public count = {
        leads: 0,
        openedLeads: 0,
        wonLeads: 0,
        lostLeads: 0,
        closedLeads: 0,
        contacts: 0,
        totalEvents: 0,
        dau: 0,
        signUp: 0,
    };
    public dealAmount = {
        total: 0,
        target: 0,
        won: 0,
        lost: 0,
        monthly: 0,
        pipeline: 0,
        totalDeals: 0,
    };
    public dateRange = getDatesRange('month');
    public startDate: Date;
    public endDate: Date;
    public isPaidUser:boolean;
    public chartData: ChartData = {
        data: [] as any,
        title: '',
        type: 'LineChart',
        columnNames: ['Month', 'Leads', 'Contacts', 'Dau', 'New SignUp', 'Revenue', 'Events'],
        options: {
            minValue: 0,
            lineWidth: 2,
            colors: ['#2C7BE5', '#714d3f', '#8e5ea2','#e6693e', '#6f9654', '#f4a261'],
            chartArea:{left: '20px',top: '20px',width: '96%',height: '65%', padding: '0px'},
            legend: { position: 'bottom', top: 10 },
            intervals: { style : 'area'},
            hAxis : {
                textStyle:{
                color: 'black',
                fontSize : 10,
                },
                slantedText: true,
                slantedTextAngle: 45,
            },
            vAxis: {
                gridlines: {
                color: 'transparent',
                },
            },
            curveType: 'function',
        },
        width: 800,
        height: 300,
    };
    public todayDate: string = moment().format('DD MMMM YYYY');
    public selectedPeriod: string = '';
    public showPdfContent: boolean = false;
    public companyLogo: any;
    public companyName: string;
    public eventsList: any[] = [];
    public currencyPrefix: string = "$";

    constructor(
        private dataService: DataService,
        private fb: FormBuilder,
        private notifier: NotifierService,
        public authService: AuthService,
        public cdr: ChangeDetectorRef,
        public userService: UserService,
        private sharedDataService: SharedDataService,
        private companyOverViewReportService: CompanyOverViewReportService,
        public eventService: EventsService,
    ) {
    this.sharedDataService.currentSlickData.subscribe(data => {
        this.slickData = data;
      });
  
      this.sharedDataService.currentChartData.subscribe(data => {
        this.chartDataVal = data;
      });

      // this.sharedDataService.currentChartImage.subscribe(image => {
      //   this.chartImage = image;
      // });

      this.sharedDataService.currentDau.subscribe(data => {
        this.dauValue = data;
      });

      this.sharedDataService.currentChurnRate.subscribe(data => {
        this.churnRate = data;
      });

      this.sharedDataService.currentRetentionRate.subscribe(data => {
        this.RetentionRate = data;
      });

      this.sharedDataService.currentTotalLeads.subscribe(data => {
        this.TotalLeads = data;
      });

      this.sharedDataService.currentTotalContacts.subscribe(data => {
        this.TotalContacts = data;
      });

      this.sharedDataService.currentTotalDealAmount.subscribe(data => {
        this.TotalDealAmount = data;
      });

      this.setSelectedPeriod();
    }

    public ngOnInit() {
        this.currencyPrefix = localStorage.getItem("currencyPrefix") || this.currencyPrefix;
        this.fetchCompanyLogo();
        track("REPORTS");
        // live status
        this.lostStatus = 'tt4nl8dJg6';
        this.wonStatus = 'lGXBCm91gj';
        this.selectedValue = 'Leads';
        this.selectedRange = 'thisMonth';
        this.showColumnFilter = false;
        this.showCustomRange = false;
        this.leadsList = true;
        this.contactsList = false;
        this.dauList = false;
        this.signUpList = false;
        this.revenueList = false;
        this.totalEvents = false;
        this.archiveModal = false;
        this.markDealModal = false;
        this.contactDeactivateModal = false;
        this.teamList = false;
        this.initializeReportForm();
        if (this.selectedValue == 'Leads') {
            this.columnFilters = ['All', 'Status', 'Stages', 'CreatedBy'];
            this.selectedFilter = 'All';
        }

        this.fetchRecords(this.selectedValue);
        this.displayChart(this.selectedValue);
        this.getEvents();
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.adjustChartWidth();
    }

    public async getEvents() {
        try {
            this.eventsList = await this.eventService.getEventsMappingList();
        } catch (e) {
            console.log(e);
        }
    }

    public ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.adjustChartWidth();
    }

    public  async setSelectedPeriod() {
        //this.selectedPeriod = await this.fetchDate();
        const result = await this.fetchDate();
        this.selectedPeriod = result.selectedPeriod;
        this.dateRange = result.dateRange;
    }

    public onSelectRangeChange() {
        this.setSelectedPeriod();
    }

    private async fetchCompanyLogo() {
        const params = {
            include: 'entityId',
            where: {
                objectId: this.authService.getUser().objectId,
            },
        };
        const paramsResponse = await this.dataService.getFromServer('classes/_User', params);
        const user = paramsResponse.results[0];
        this.companyName = user.entityId && user.entityId.name ? user.entityId.name : user.name
        this.companyLogo = user.profilePhoto && user.profilePhoto.url ? user.profilePhoto.url : '../../../assets/images/company-logo-placeholder.png';
    }

    public async onloadDetails() {
        const status = await this.dataService.getFromServer('classes/Status');
        this.status = status.results;
       const params = {
            where: {
              space: { $exists: true }, 
              isArchived:  { $ne: true }
            },
          }
        const stages = await this.dataService.getFromServer('classes/Stages',params);
        this.stages = stages.results;
    }

    private initializeReportForm() {
        this.reportForm = this.fb.group({
            // status: [''],
            // stage: [''],
            className: ['Leads'],
            startDate: [new Date(), Validators.required],
            endDate: [new Date(), Validators.required],
            selectRange: [],
            filterByColumn: [],
            filterByValue: [],
            amountInput: []
        });
    }

    public async onMetricsChange() {
        this.columnFilters = [];
        this.showColumnFilterValues = false;
        this.showColumnFilter = true;
        if (this.selectedValue == 'Leads') {
            this.columnFilters = ['All', 'Stages', 'CreatedBy'];
        } else if (this.selectedValue == 'Contacts') {
            this.showColumnFilter = false;
        } else if (this.selectedValue == 'Dau') {
            this.showColumnFilter = false;
        } else if (this.selectedValue == 'NewSignUp') {
            this.showColumnFilter = false;
        } else if (this.selectedValue == 'Revenue') {
            this.columnFilters = ['All', 'Amount', 'CreatedBy'];
        } else if (this.selectedValue === 'CompanyOverViewReport') {
            this.showColumnFilter = false;
        } else if (this.selectedValue === 'Events') {
            this.showColumnFilter = false;
            this.showColumnFilterValues = true;
            this.filterByColumns();
        } else {
            this.columnFilters = ['All', 'CreatedBy'];
        }
    } 

    public async filterByColumns() {
        if ((this.selectedFilter !== 'All' && this.selectedValue !== 'Contacts') || this.selectedValue === 'Events') {
            this.showColumnFilterValues = true;
            this.showAmountInput = false;
            this.columnFilterValues = [];
            if (this.selectedFilter == 'CreatedBy') {
                await this.fetchCreatedByList();
            } else if (this.selectedFilter == 'Amount') {
                this.showAmountInput = true;
                this.columnFilterValues = [{ id: '$lt', value: '> lessThan' },
                { id: '$gt', value: '< greaterThan' },
                { id: 'equalTo', value: '= EqualTo' },
                { id: '$lte', value: '>= lessThanOrEqualTo ' },
                { id: '$gte', value: '<= greaterThanOrEqualTo' }
                ];
                this.selectedFilterValue = this.columnFilterValues[0].id;
            } else if (this.selectedFilter == 'Stages') {
                await this.fetchStagesList();
            } else if ((this.selectedFilter !== 'All' && this.selectedValue === 'Events') || this.selectedValue === 'Events') {

                this.columnFilterValues = await getEventnames(this.eventsList);
                this.selectedFilterValue = this.columnFilterValues[0].id;
            } else { }
        } else {
            this.showColumnFilterValues = false;
        }
    }

    private async fetchStagesList() {
        const params = {
            include: 'entity',
            where: {
                entity: {
                    __type: 'Pointer',
                    className: 'Entity',
                    objectId: this.authService.getUser().entityId.objectId,
                },
                space: { $exists: true }, 
                isArchived:  { $ne: true }
            },
        };
        const paramsResponse = await this.dataService.getFromServer(
            'classes/Stages',
            params,
        );
        const stagesList = paramsResponse.results;
        stagesList.forEach((stage: any) => {
            this.columnFilterValues.push({ id: stage.objectId, value: stage.name })
        });
        this.selectedFilterValue = this.columnFilterValues[0].id;
    }

    private async fetchCreatedByList() {
        const params = {
            entityId: this.authService.getUser().entityId.objectId,
        };
        const paramsResponse = await this.dataService.postToServer(
            'functions/getUsersList',
            params,
        );
        const userList = JSON.parse(paramsResponse.result);
        userList.forEach((user: any) => {
            this.columnFilterValues.push({ id: user.objectId, value: user.name })
        });
        this.selectedFilterValue = this.columnFilterValues[0].id;
    }

    public async fetchDate() {
        this.showCustomRange = false;
        let selectedPeriod: string = '';
        let date: any;
        if(this.selectedRange !== 'custom' || !!this.selectedRange) {
            date = getDatesRange(this.selectedRange || 'thisMonth')
            this.dateRange.startDate = date.startDate;
            this.dateRange.endDate = date.endDate;

        }
        if (this.selectedRange === 'thisYear') {
            selectedPeriod = 'This Year';
        } else if (this.selectedRange === 'thisWeek') {
            selectedPeriod = 'This Week';
        } else if (this.selectedRange === 'lastMonth') {
            selectedPeriod = 'Last Month';
        } else if (this.selectedRange === 'lastYear') {
            selectedPeriod = 'Last Year';
        } else if (this.selectedRange === 'lastWeek') {
            selectedPeriod = 'Last Week';
        } else if (this.selectedRange === 'custom') {
            this.showCustomRange = true;
            this.dateRange.startDate = this.reportForm.get('startDate')!.value;
            this.dateRange.endDate = this.reportForm.get('endDate')!.value;
            selectedPeriod = 'Custom Range';
        } else {
            selectedPeriod = 'This Month';
        }
        //return selectedPeriod;
        return {
            selectedPeriod: selectedPeriod,
            dateRange: {
                startDate: this.dateRange.startDate,
                endDate: this.dateRange.endDate
            }
        };
    }

    onSelectionChange(event: MatSelectChange): void {
        const selectedOptions = event.value;
        this.selectedFilterValue = selectedOptions.length > 0 ? selectedOptions : 'ALL';
    }

    //fetchRecords - fetchData
    public async fetchRecords(data: string) {
        try {
            this.isLoading = true;
            let params: any = {};
            let paramsResponse: any;
            if (this.selectedRange === 'custom') {
                await this.fetchDate();
            }

            const startDate = moment(this.dateRange.startDate).startOf('day').toDate().toISOString();
            const endDate = moment(this.dateRange.endDate).endOf('day').toDate().toISOString();

            if (data === 'CompanyOverViewReport') {
                try {
                    if (this.userService.getUser()) {
                        this.companyOverViewReportData = await this.companyOverViewReportService.dailyUsersCount(this.selectedRange)
                    }
                    this.displayChart(data);
                    this.signUpList = false;
                    this.dauList = false;
                    this.revenueList = false;
                    this.totalEvents = false;
                    this.leadsList = false;
                    this.contactsList = false;
                    this.isLoading = false;
                } catch (e) {
                    this.isLoading = false;
                    console.log('e', e);
                }
            }
            if (data === 'Leads') {
                // await this.getSummaryData();
                this.leadsList = true;
                this.contactsList = false;
                this.revenueList = false;
                this.totalEvents = false;
                this.dauList = false;
                this.signUpList = false;
                params = {
                    include: 'lead,agent,contact,stage,status,creator',
                    where: {
                        entity: {
                            __type: 'Pointer',
                            className: 'Entity',
                            objectId: this.authService.getUser().entityId.objectId,
                        },
                        isArchived: {$ne: true},
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                    },
                    limit:100000,
                };                              
                if (this.selectedValue == 'Leads' && this.selectedFilter == 'CreatedBy') {
                    params.where["creator"] = {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: this.selectedFilterValue,
                    };
                }
                if (this.selectedFilter == 'Stages') {
                    params.where["stage"] = {
                        __type: 'Pointer',
                        className: 'Stages',
                        objectId: this.selectedFilterValue,
                    };
                }
                paramsResponse = await this.dataService.getFromServer(
                    'classes/Leads',
                    params,
                );

                this.reportsList = paramsResponse.results || [];
                this.count.leads = this.reportsList.length;

                const dtOptions = {
                    dom: '<"appRequestsFilter"lf>tipr',
                    columnDefs: [
                        {
                            orderable: false,
                            targets: 0,
                            responsivePriority: 1,
                            width: '25px',
                        },
                        {
                            responsivePriority: 1,
                            targets: 1,
                        },
                        {
                            responsivePriority: 1,
                            targets: 7,
                        },
                        {
                            defaultContent: 'NA',
                            targets: '_all',
                        },
                    ],
                    language: {
                        search: '_INPUT_',
                        searchPlaceholder: 'Search...',
                        lengthMenu: '_MENU_',
                    },
                    order: [
                        [0, 'desc'],
                    ],
                    responsive: true,
                    data: this.reportsList,
                    columns: [
                        {
                            data: '',
                            className: 'responsive-plus',
                        },
                        {
                            title: 'Lead',
                            data: (row: Leads) => {
                                return (
                                    '<div style="max-width:200px; white-space:normal;">' +
                                    (row.description ? row.description : 'NA') +
                                    '</div>'
                                );
                            },
                        },
                        {
                            title: 'Customer',
                            data: (row: Leads) => {
                                return row.contact ? row.contact.name : 'NA';
                            },
                        },
                        {
                            title: 'Created',
                            data: (row: Leads) => {
                                return moment(row.createdAt).format('DD/MMM/YYYY')
                            },
                        },
                        {
                            title: 'Created By',
                            data: (row: Leads) => {
                                return row.creator ? row.creator.name : 'NA';
                            },
                        },
                        {
                            title: 'Status',
                            data: (row: Leads) => {
                                return row.status ? row.status.name : 'NA';
                            },
                        },
                        {
                            title: 'Stage',
                            data: (row: Leads) => {
                                return row.stage ? row.stage.name : 'NA';
                            },
                        },
                        {
                            title: 'Actions',
                            data: (row: Leads) => {
                                const handShakeIcon = !row.status ? '<span class="icon hand-shake-icon"></span>&nbsp;&nbsp;&nbsp;&nbsp;' : '';
                                return handShakeIcon + '<span class="fa fa-archive"></span>';
                            },
                        },
                    ],
                    rowCallback: (row: Node, data: object) => {
                        row.removeEventListener('click', () => {
                        });
                        row.addEventListener('click', e => {
                            if ((e.target as HTMLElement).classList.contains('hand-shake-icon')) {
                                this.markAsDone((data as Leads).objectId);
                            } else if ((e.target as HTMLElement).classList.contains('fa-archive')) {
                                this.archiveLead((data as Leads).objectId);
                            }
                        });
                        // @ts-ignore
                        row.setAttribute('id', `id-${(data as Leads).objectId}`);
                        return row;
                    },
                };
                this.renderTable(dtOptions);
                this.displayChart(data);
                this.isLoading = false;
            }
            if (data === 'Contacts') {
                this.contactsList = true;
                this.leadsList = false;
                this.revenueList = false;
                this.totalEvents = false;
                this.dauList = false;
                this.signUpList = false;
                params = {
                    entityId: this.authService.getUser().entityId.objectId,
                    startDate,
                    endDate,
                };
                paramsResponse = await this.dataService.postToServer(
                    'functions/getAllContacts',
                    params,
                );
                this.reportsList = paramsResponse.result || [];
                if (this.reportsList.length > 0) {
                    this.count.contacts = this.reportsList.length;
                }
                const dtOptions = {
                    order: [
                        [0, 'desc'],
                    ],
                    columnDefs: [
                        {
                            orderable: false,
                            targets: 0,
                            responsivePriority: 1,
                            width: '25px',
                        },
                        {
                            responsivePriority: 1,
                            targets: 1,
                        },
                        {
                            responsivePriority: 1,
                            targets: 5,
                        },
                        {
                            defaultContent: 'NA',
                            targets: '_all',
                        },
                    ],
                    responsive: true,
                    data: this.reportsList,
                    columns: [
                        {
                            data: '',
                            className: 'responsive-plus',
                        },
                        {
                            title: 'Name',
                            data: (row: Contacts) => {
                                return row.name ? row.name : 'NA';
                            },
                        },
                        {
                            title: 'Email',
                            data: (row: Contacts) => {
                                return row.email ? row.email : 'NA';
                            },
                        },
                        {
                            title: 'PhoneNumber',
                            data: (row: Contacts) => {
                                return row.phoneNumber ? row.phoneNumber : 'NA';
                            },
                        },
                        {
                            title: 'Total Leads',
                            data: (row: Contacts) => {
                                return row.totalLeads || 0;
                            },
                        },
                        {
                            title: 'Actions',
                            data: () => {
                                return '<span class="icon deactivate-icon"></span>';
                            },
                        },
                    ],
                    rowCallback: (row: Node, data: object) => {
                        row.removeEventListener('click', () => {
                        });
                        row.addEventListener('click', e => {
                            if ((e.target as HTMLElement).classList.contains('deactivate-icon')) {
                                this.openContactModal((data as Contacts).objectId);
                            }
                        });
                        // @ts-ignore
                        row.setAttribute('id', `id-${(data as Leads).objectId}`);
                        return row;
                    },
                };
                this.renderTable(dtOptions);
                this.displayChart(data);
                this.isLoading = false;
            }
            if (data === 'Dau') {
                this.dauList = true;
                this.revenueList = false;
                this.totalEvents = false;
                this.leadsList = false;
                this.contactsList = false;
                this.signUpList = false;
                const params = {
                    entityId: this.authService.getUser().entityId.objectId,
                    startDate,
                    endDate,
                    typeOfData: 'Reports',
                }
                paramsResponse = await this.dataService.postToServer(
                    "functions/getActiveUsersMetrics",
                    params
                );
                this.reportsList = paramsResponse.result || [];
                const totalCount = this.reportsList.reduce((sum: any, report: any) => sum + (report.count || 0), 0);
                 this.count.dau = this.reportsList.length > 0 ? totalCount / this.reportsList.length : 0;
               // this.count.dau = totalCount;                

                const contactList = await convertData(this.reportsList, 'Dau')

                const dtOptions = {
                    order: [
                        [0, 'desc'],
                    ],
                    columnDefs: [
                        {
                            orderable: false,
                            targets: 0,
                            responsivePriority: 1,
                            width: '25px',
                        },
                        {
                            responsivePriority: 1,
                            targets: 1,
                        },
                        {
                            defaultContent: 'NA',
                            targets: '_all',
                        },
                    ],
                    responsive: true,
                    data: contactList.allRecors,
                    columns: [
                        {
                            data: '',
                            className: 'responsive-plus',
                        },
                        {
                            title: 'Name',
                            data: (row: any) => {
                                return row.contactDetails && row.contactDetails.name ? row.contactDetails.name : 'NA';
                            },
                        },
                        {
                            title: 'Email',
                            data: (row: any) => {
                                return row.contactDetails && row.contactDetails.email ? row.contactDetails.email : 'NA';
                            },
                        },
                        {
                            title: 'domain',
                            data: (row: any) => {
                                return row.domain ? row.domain : 'NA';
                            },
                        },
                        {
                            title: 'Created At',
                            data: (row: any) => {
                                return row._created_at ? moment(row._created_at).format('MM/DD/YYYY') : 'NA';
                            },
                        },
                    ],
                    rowCallback: (row: Node, data: object) => {
                        // Custom row callback if needed
                        // @ts-ignore
                        row.setAttribute('id', `id-${(data as Dau).objectId}`);
                        return row;
                    },

                };
                this.renderTable(dtOptions);
                this.displayChart(data);
                this.isLoading = false;
            }
            if (data === 'NewSignUp') {
                this.signUpList = true;
                this.dauList = false;
                this.revenueList = false;
                this.totalEvents = false;
                this.leadsList = false;
                this.contactsList = false;
                const params = {
                    user: this.authService.getUser(),
                    entityId: this.authService.getUser().entityId.objectId,
                    startDate,
                    endDate,
                    limit:100000,
                }
                paramsResponse = await this.dataService.postToServer(
                    "functions/getCustomerEventSignupMetrics",
                    params
                );
                this.reportsList = paramsResponse.result || [];
                const totalCount = this.reportsList.reduce((sum: any, report: any) => sum + (report.count || 0), 0);
                if (this.reportsList.length > 0) {
                    this.count.signUp = totalCount;
                }
                const dtOptions = {
                    order: [
                        [0, 'desc'],
                    ],
                    columnDefs: [
                        {
                            orderable: false,
                            targets: 0,
                            responsivePriority: 1,
                            width: '25px',
                        },
                        {
                            responsivePriority: 1,
                            targets: 1,
                        },
                        {
                            defaultContent: 'NA',
                            targets: '_all',
                        },
                    ],
                    responsive: true,
                    data: this.reportsList,
                    columns: [
                        {
                            data: '',
                            className: 'responsive-plus',
                        },
                        {
                            title: 'Count',
                            data: (row: any) => {
                                return row.count ? row.count : 'NA';
                            },
                        },
                        {
                            title: 'Created At',
                            data: (row: any) => {
                                return row.objectId && row.objectId.date ? moment(row.objectId.date).format('MM/DD/YYYY') : 'NA';
                            },
                        },
                    ],
                    rowCallback: (row: Node, data: object) => {
                        // Custom row callback if needed
                        // @ts-ignore
                        row.setAttribute('id', `id-${(data as NewSignUp).objectId}`);
                        return row;
                    },

                };
                this.renderTable(dtOptions);
                this.displayChart(data);
                this.isLoading = false;
            }
            if (data === 'Revenue') {
                this.revenueList = true;
                this.totalEvents = false;
                this.leadsList = false;
                this.contactsList = false;
                this.dauList = false;
                this.signUpList = false;
                params = {
                    include: 'lead,contact,status,stage,creator',
                    where: {
                        entity: {
                            __type: 'Pointer',
                            className: 'Entity',
                            objectId: this.authService.getUser().entityId.objectId,
                        },
                        isArchived: { $ne: true },
                        createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } },
                    },
                    limit:100000,
                };
                if (this.selectedFilter == 'Amount') {
                    if (this.selectedFilterValue == 'equalTo') {
                        params.where.dealValue = this.amountInputValue;
                    } else {
                        params.where.dealValue = { [this.selectedFilterValue]: this.amountInputValue }
                    }
                }
                if (this.selectedFilter == 'CreatedBy') {
                    params.where["creator"] = {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: this.selectedFilterValue,
                    };
                }
                paramsResponse = await this.dataService.getFromServer(
                    'classes/Leads',
                    params,
                );
                this.reportsList = paramsResponse.results || [];
                if (this.reportsList.length > 0) {
                    this.dealAmount.totalDeals = this.reportsList.length;
                    this.dealAmount.total = 0;
                    this.dealAmount.won = 0;
                    this.dealAmount.lost = 0;
                    this.reportsList.forEach((lead: Leads) => {
                        if (lead.status && lead.status.name === 'Won') {
                            this.dealAmount.won = (lead.dealValue) ? (this.dealAmount.won + lead.dealValue) : this.dealAmount.won;
                        }
                        if (lead.status && lead.status.name === 'Lost') {
                            this.dealAmount.lost = (lead.dealValue) ? (this.dealAmount.lost + lead.dealValue) : this.dealAmount.lost;
                        }
                        if (lead.dealValue && lead.dealValue > 0) {
                            this.dealAmount.total = this.dealAmount.total + lead.dealValue;
                        }
                        this.dealAmount.pipeline = this.dealAmount.won ? (this.dealAmount.total - this.dealAmount.won) : this.dealAmount.total;
                    })
                }
                const dtOptions = {
                    order: [
                        [0, 'desc'],
                    ],
                    columnDefs: [
                        {
                            orderable: false,
                            targets: 0,
                            responsivePriority: 1,
                            width: '25px',
                        },
                        {
                            responsivePriority: 1,
                            targets: 1,
                        },
                        {
                            responsivePriority: 1,
                            targets: 6,
                        },
                        {
                            defaultContent: 'NA',
                            targets: '_all',
                        },
                    ],
                    responsive: true,
                    data: this.reportsList,
                    columns: [
                        {
                            data: '',
                            className: 'responsive-plus',
                        },
                        {
                            title: 'Lead',
                            data: (row: Leads) => {
                                return (
                                    '<div style="max-width:200px; white-space:normal;">' +
                                    (row.description ? row.description : 'NA') +
                                    '</div>'
                                );
                            },
                        },
                        {
                            title: 'Customer',
                            data: (row: Leads) => {
                                return row.contact ? row.contact.name : 'NA';
                            },
                        },
                        {
                            title: 'Amount',
                            data: (row: Leads) => {
                                return row.dealValue ? row.dealValue : 'NA';
                            },
                        },
                        {
                            title: 'Created',
                            data: (row: Leads) => {
                                return moment(row.createdAt).format('DD/MMM/YYYY')
                            },
                        },
                        {
                            title: 'Created By',
                            data: (row: Leads) => {
                                return row.creator ? row.creator.name : 'NA';
                            },
                        },
                        {
                            title: 'Actions',
                            data: (row: Leads) => {
                                const handShakeIcon = !row.status ? '<span class="icon hand-shake-icon"></span>&nbsp;&nbsp;&nbsp;&nbsp;' : '';
                                return handShakeIcon + '<span class="fa fa-archive"></span>';
                            },
                        },
                    ],
                    rowCallback: (row: Node, data: object) => {
                        row.removeEventListener('click', () => {
                        });
                        row.addEventListener('click', e => {
                            if ((e.target as HTMLElement).classList.contains('hand-shake-icon')) {
                                this.markAsDone((data as Leads).objectId);
                            } else if ((e.target as HTMLElement).classList.contains('fa-archive')) {
                                this.archiveLead((data as Leads).objectId);
                            }
                        });
                        // @ts-ignore
                        row.setAttribute('id', `id-${(data as Leads).objectId}`);
                        return row;
                    },
                }
                this.renderTable(dtOptions);
                this.displayChart(data);
                this.isLoading = false;
            }
            if (data === 'Events') {
                this.totalEvents = true;
                this.contactsList = false;
                this.leadsList = false;
                this.revenueList = false;
                this.dauList = false;
                this.signUpList = false;
                params = {
                    entityId: this.authService.getUser().entityId.objectId,
                    startDate,
                    endDate,
                    typeOfData: 'Reports',
                    ...(this.selectedFilterValue !== 'ALL' && { eventName: this.selectedFilterValue }),
                };
                console.log("selectedFilterValue::", this.selectedFilterValue)
                if (this.selectedFilter == 'CreatedBy') {
                    params['userId'] = this.selectedFilterValue;
                }

                paramsResponse = await this.dataService.postToServer(
                    'functions/getActiveUsersMetrics',
                    params,
                );

                this.reportsList = paramsResponse.result || [];
                if (this.reportsList.length > 0) {
                    this.count.totalEvents = this.reportsList.reduce((sum: any, report: any) => sum + (report.count || 0), 0);
                }

                const eventsList = await convertData(this.reportsList, 'Events');

                const dtOptions = {
                    order: [
                        [0, 'desc'],
                    ],
                    columnDefs: [
                        {
                            orderable: false,
                            targets: 0,
                            responsivePriority: 1,
                            width: '25px',
                        },
                        {
                            responsivePriority: 1,
                            targets: 1,
                        },
                        {
                            defaultContent: 'NA',
                            targets: '_all',
                        },
                    ],
                    responsive: true,
                    data: eventsList.allRecors,
                    columns: [
                        {
                            data: '',
                            className: 'responsive-plus',
                        },
                        {
                            title: 'Name',
                            data: (row: any) => {
                                return row.contactDetails && row.contactDetails.name ? row.contactDetails.name : 'NA';
                            },
                        },
                        {
                            title: 'Email',
                            data: (row: any) => {
                                return row.contactDetails && row.contactDetails.email ? row.contactDetails.email : 'NA';
                            },
                        },
                        {
                            title: 'domain',
                            data: (row: any) => {
                                return row.domain ? row.domain : 'NA';
                            },
                        },
                        {
                            title: 'Created At',
                            data: (row: any) => {
                                return row._created_at ? moment(row._created_at).format('MM/DD/YYYY') : 'NA';
                            },
                        },
                    ],
                };
                this.renderTable(dtOptions);
                this.displayChart(data);
                this.isLoading = false;
            }
        } catch (error) {
            this.isLoading = false;
        }
    }
    //fetchRecords

    generatePDF() {
        const doc = new jsPDF();
    
        // Company logo
        const companyLogo = new Image();
        companyLogo.src = this.companyLogo;
        const logoWidth = 20;
    
        companyLogo.onload = () => {
            const logoHeight = (companyLogo.height * logoWidth) / companyLogo.width;
    
            // Header
            const todayDate = this.todayDate;
    
            // Report Overview
            const reportName = `${this.selectedValue !== 'CompanyOverViewReport' ? this.selectedValue : 'Company OverView Report'}`;
            //const periodText = `Period: ${this.selectedPeriod}`;
            const periodText = `Period: [ ${moment(this.dateRange.startDate).format('DD MMM YY')} - ${moment(this.dateRange.endDate).format('DD MMM YY')} ]`;
    
            // PDF settings
            const pdfWidth = doc.internal.pageSize.getWidth();
            let currentY = 10;
    
            // Logo and Date
            doc.addImage(companyLogo, 'PNG', 10, currentY, logoWidth, logoHeight);
            doc.setFontSize(14);
            doc.text(todayDate, pdfWidth - 10, 20, { align: 'right' });

            // Company Name
            doc.setFontSize(16);
            doc.setFont('helvetica', 'bold');
            doc.text(this.companyName, 33, 20);
    
            currentY += logoHeight + 10;
    
            // Report Overview
            doc.setFontSize(16);
            doc.setFont('helvetica', 'bold');
            doc.text(reportName, 20, currentY + 12);
            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');
            doc.text(periodText, pdfWidth - 20, currentY + 12, { align: 'right' });
    
            currentY += 35;

            // Dashboard Metrics Data
            const dashboardMetricsData = [
                { title: 'DAU', value: this.companyOverViewReportData.dailyActiveUsers || 0 },
                { title: 'Churn Rate', value: `${this.companyOverViewReportData.churnRate}%` || 0},
                { title: 'Retention Rate', value: `${this.companyOverViewReportData.retentionRate}%` || 0},
                { title: 'Total Leads', value: this.companyOverViewReportData.totalLeads || 0},
                { title: 'Total Contacts', value: this.companyOverViewReportData.totalContacts || 0 },
                { title: 'Total Deal Amount', value: `$${this.companyOverViewReportData.totalDealAmount}` || 0 }
            ];
    
            // // SlickTableData
            // const slickTableData = [];
            // for (let i = 0; i < this.slickData.length; i++) {
            //     slickTableData.push([this.slickData[i].title, this.slickData[i].numbers]);
            // }

            const lineHeight = 10;
            const colWidth = pdfWidth / 3;
            const startX = 20;
    
            dashboardMetricsData.forEach((item, index) => {
                const colX = startX + (index % 3) * colWidth;
                if (index % 3 === 0 && index > 0) {
                    currentY += lineHeight * 2; // Move to the next line for each new row
                }
    
                // Heading
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                doc.text(item.title, colX, currentY);
    
                // Value
                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.text(item.value.toString(), colX, currentY + lineHeight);
            });
    
            currentY = lineHeight + 90;
    
            // Chart Image
            if (this.chartImage) { 
                // Ensure the chart image is correctly loaded and dimensions are set
                const img = new Image();
                img.src = this.chartImage;
                img.onload = () => {
                    const chartImageWidth = pdfWidth - 40; // Adjust as needed
                    const chartImageHeight = (img.height * chartImageWidth) / img.width;
                    currentY += lineHeight * 2;
                    doc.addImage(img, 'PNG', 20, currentY, chartImageWidth, chartImageHeight);
                    doc.save('Company_OverView_Report.pdf');
                };
                img.onerror = (error) => {
                    console.error('Failed to load the chart image', error);
                };
            } else {
                doc.save('Company_OverView_Report.pdf');
            }
        };
    
        companyLogo.onerror = (error) => {
            console.error('Failed to load the company logo image', error);
        };
    }

    private adjustChartWidth() {
        if (this.chartContainer) {
          const containerWidth = this.chartContainer.nativeElement.offsetWidth;
          this.chartData.width = containerWidth;
          this.displayChart(this.selectedValue);
        }
      }

    private displayChart(dataType: string) {
        const chartWidth = this.chartContainer ? this.chartContainer.nativeElement.offsetWidth : 0;
        if(chartWidth){
            if(dataType !== 'CompanyOverViewReport') {
                const resp = convertData(this.reportsList, dataType)

                let maxValue = resp && resp.maxValue ? resp.maxValue : 20;

                let color = chartColors[dataType]

                this.chartData = {
                    title: `${dataType} Report`,
                    type: 'LineChart',
                    data: resp.data || [],
                    columnNames: ['Date', `Total ${dataType}`],
                    options: {
                        colors: [color],
                        chartArea: {left: '50px', top: '20px', width: '90%', height: '65%', padding: '0px'},
                        legend: {position: 'bottom', top: 10},
                        intervals: {style: 'area'},
                        hAxis: {
                            title: 'Date',
                            format: 'MMM dd, yyyy',
                            textStyle: {color: 'black', fontSize: 10},
                            // slantedText: true,
                            // slantedTextAngle: 45,
                            gridlines: {color: 'transparent'},
                        },
                        vAxis: {
                            title: `${dataType} Count`,
                            minValue: 0,
                            maxValue: maxValue,
                            textStyle: {color: 'black', fontSize: 8, whiteSpace: 'no-wrap',},
                            gridlines: {color: 'transparent'},
                            viewWindow: {min: 0, max: maxValue},
                        },
                        lineWidth: 2,
                        curveType: 'function',
                        pointsVisible: true,
                        pointSize: 5,
                    },
                    width: Math.round(chartWidth - 30),
                    height: 400,
                };
            }else {
                this.chartData = {
                    title: `Company OverView Report Report`,
                    type: 'LineChart',
                    data: this.companyOverViewReportData.graphDate || [],
                    columnNames: ['Month', 'Daily Active Users', 'New Signup', 'New Leads'],
                    options: {
                        colors: ['#2C7BE5', '#714d3f', '#0F0', '#F00'],
                        chartArea: {left: '50px', top: '20px', width: '90%', height: '65%', padding: '0px'},
                        legend: {position: 'bottom', top: 10},
                        intervals: {style: 'area'},
                        hAxis: {
                            title: 'Date',
                            format: 'MMM dd, yyyy',
                            textStyle: {color: 'black', fontSize: 10},
                            gridlines: {color: 'transparent'},
                        },
                        vAxis: {
                            title: `Count`,
                            minValue: 0,
                            textStyle: {color: 'black', fontSize: 8, whiteSpace: 'no-wrap',},
                            gridlines: {color: 'transparent'},
                            viewWindow: {min: 0},
                        },
                        lineWidth: 2,
                        curveType: 'function',
                        pointsVisible: true,
                        pointSize: 5,
                    },
                    width: Math.round(chartWidth - 30),
                    height: 400,
                };
            }
        }
    }

    public async markAsDone(leadId: string) {
        this.markDealModal = true;
        this.leadId = leadId;
    }

    public declineDeal() {
        this.markDealModal = false;
    }

    public async archiveLead(leadId: string) {
        this.archiveModal = true;
        this.leadId = leadId;
    }

    public async clickYes() {
        this.archiveModal = false;
        try {
            await this.dataService.updateToServer('classes/Leads/' + this.leadId, {
                isArchived: true,
            });
            this.notifier.notify('success', 'Lead Archived Successfully !!!');
            this.reportsList = [...this.reportsList].filter(
                data => data.objectId !== this.leadId,
            );
            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.row(`#id-${this.leadId}`).remove();
                dtInstance.draw();
            });
            if (this.selectedValue === 'Leads') {
                // await this.getSummaryData();
                this.count.leads = this.reportsList.length;
            } else {
                this.dealAmount.totalDeals = this.reportsList.length;
                this.reportsList.forEach((lead: Leads) => {
                    if (lead.status && lead.status.name === 'Won') {
                        this.dealAmount.won = (lead.dealValue) ? (this.dealAmount.won + lead.dealValue) : this.dealAmount.won;
                    }
                    if (lead.status && lead.status.name === 'Lost') {
                        this.dealAmount.lost = (lead.dealValue) ? (this.dealAmount.lost + lead.dealValue) : this.dealAmount.lost;
                    }
                    if (lead.dealValue && lead.dealValue > 0) {
                        this.dealAmount.total = this.dealAmount.total + lead.dealValue;
                    }
                    this.dealAmount.pipeline = this.dealAmount.won ? (this.dealAmount.total - this.dealAmount.won) : this.dealAmount.total;
                })
            }
        } catch (e) {
            // alert(e.message);
            this.notifier.notify('error', e.message);
        }
    }

    public async decline() {
        this.archiveModal = false;
    }

    public openContactModal(contactId: string) {
        this.contactDeactivateModal = true;
        this.contactId = contactId;
    }

    public async deactivateContact() {
        this.contactDeactivateModal = false;
        try {
            await this.dataService.updateToServer('classes/Contacts/' + this.contactId, {
                isActive: false,
            });
            this.notifier.notify('success', 'Contact Deactivated Successfully !!!');
            this.reportsList = [...this.reportsList].filter(
                data => data.objectId !== this.contactId,
            );
            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.row(`#id-${this.contactId}`).remove();
                dtInstance.draw();
            });
            this.count.contacts = this.reportsList.length;
        } catch (e) {
            // alert(e.message);
            this.notifier.notify('error', e.message);
        }
    }

    public closeContactModal() {
        this.contactDeactivateModal = false;
    }

    public async exportToPdf(selectedValue: string) {
        if(selectedValue !== 'CompanyOverViewReport') {
            this.showPdfContent = true;
            await this.fetchCompanyLogo();
            await this.fetchRecords(this.selectedValue);
            const doc = new jsPDF();

            doc.addFont("Montserrat-Bold.ttf", "Montserrat", "bold");

            const setupPdf = async () => {
                const companyLogo = new Image();
                companyLogo.src = this.companyLogo;
                const logoHeight = 20;
                const pdfWidth = doc.internal.pageSize.getWidth();
                let yPos = 10;

                // Add logo and date
                doc.addImage(companyLogo, 'PNG', 10, 10, 20, 16);
                doc.setFontSize(12);
                doc.text(this.todayDate, pdfWidth - 10, 20, {align: 'right'});
                yPos += logoHeight + 10;

                // Company Name
                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.text(this.companyName, 33, 20);

                // Add header content
                const pdfHeader = this.pdfHeader.nativeElement;
                if (pdfHeader) {
                    doc.setDrawColor(0);
                    doc.setFillColor(40, 127, 186);
                    doc.rect(10, 40, 190, 15, "F");

                    doc.setTextColor(255, 255, 255);
                    doc.setFontSize(14);
                    doc.setFont("helvetica", "bold");
                    doc.text(`${this.selectedValue} Report`, 15, 50);
                    yPos += 10 + 10;
                }
                return yPos;
            };

            const addChart = async (doc: jsPDF, yPos: number) => {
                const chartContainer = this.chartContainer.nativeElement;
                if (chartContainer) {
                    const canvasD = await html2canvas(chartContainer);
                    const imageData1 = canvasD.toDataURL('image/png');

                    // Ensure to specify valid width and height for the image
                    const imageWidth = 180;
                    const imageHeight = 120;

                    // Add image to PDF
                    doc.addImage(imageData1, 'PNG', 10, yPos, imageWidth, imageHeight);

                    // Update yPos for next content
                    return yPos + imageHeight + 20;
                } else {
                    throw new Error('No Chart Found!!');
                }
            };

            const addTable = (doc: jsPDF, yPos: number, headers: string[], data: any[], summaryHeaders?: string[], summaryData?: any[]) => {
                let finalY: number = yPos;

                const updateYPos = (yPos: number) => {
                    if (finalY < yPos) {
                        finalY = yPos;
                    }
                };

                autoTable(doc, {
                    startY: yPos,
                    head: [headers],
                    body: data,
                    didDrawCell: (data) => {
                        updateYPos(data.cell.y + data.cell.height);
                    }
                });

                if (summaryHeaders && summaryData) {
                    autoTable(doc, {
                        startY: finalY + 10,
                        head: [summaryHeaders],
                        body: [summaryData],
                        didDrawCell: (data) => {
                            updateYPos(data.cell.y + data.cell.height);
                        }
                    });
                }

                return finalY + 10;
            };

            const savePdf = (fileName: string) => {
                doc.save(`${fileName}_Report.pdf`);
                this.showPdfContent = false;
            };

            try {
                let yPos = await setupPdf();
                switch (selectedValue) {
                    case 'Leads':
                        if (this.reportsList.length > 0) {
                            yPos = await addChart(doc, yPos);
                            const pdfData = this.reportsList.map((lead: Leads) => [
                                lead.description || '',
                                lead.contact ? lead.contact.name : 'NA',
                                moment(lead.createdAt).format('MM/DD/YYYY'),
                                lead.creator ? lead.creator.name : 'NA',
                                lead.status ? lead.status.name : 'NA',
                                lead.stage ? lead.stage.name : 'NA'
                            ]);
                            yPos = addTable(doc, yPos, ['Lead', 'Customer', 'Created', 'Created By', 'Status', 'Stage'], pdfData, ['Total Leads'], [this.reportsList.length]);
                            savePdf('Leads');
                        } else {
                            throw new Error('No Results Found!!');
                        }
                        break;

                    case 'Contacts':
                        if (this.reportsList.length > 0) {
                            yPos = await addChart(doc, yPos);
                            const pdfData = this.reportsList.map((contact: Contacts) => [
                                contact.name || 'NA',
                                contact.email || 'NA',
                                contact.phoneNumber || 'NA',
                                contact.totalLeads || 0
                            ]);
                            yPos = addTable(doc, yPos, ['Name', 'Email', 'PhoneNumber', 'Total Leads'], pdfData, ['Total Contacts'], [this.reportsList.length]);
                            savePdf('Contacts');
                        } else {
                            throw new Error('No Results Found!!');
                        }
                        break;

                    case 'Revenue':
                        if (this.reportsList.length > 0) {
                            yPos = await addChart(doc, yPos);
                            const pdfData = this.reportsList.map((lead: Leads) => [
                                lead.description || 'NA',
                                lead.contact ? lead.contact.name : 'NA',
                                lead.dealValue || 0,
                                lead.status ? lead.status.name : 'NA',
                                lead.creator ? lead.creator.name : 'NA'
                            ]);
                            yPos = addTable(doc, yPos, ['Lead', 'Customer', 'Amount', 'Status', 'Created By'], pdfData, ['Total Deals', 'Total Revenue', 'Total Pipeline'], [this.reportsList.length, this.dealAmount.won, this.dealAmount.pipeline]);
                            savePdf('Revenue');
                        } else {
                            throw new Error('No Results Found!!');
                        }
                        break;

                    case 'Events':
                        if (this.reportsList.length > 0) {

                            const totalCount = this.reportsList.reduce((sum: any, report: any) => sum + (report.count || 0), 0);

                            const contactList = await convertData(this.reportsList, 'Events')

                            const data = contactList.allRecors;

                            yPos = await addChart(doc, yPos);
                            const pdfData = data.map((row: any) => [
                                row.contactDetails && row.contactDetails.name ? row.contactDetails.name : 'NA',
                                row.contactDetails && row.contactDetails.email ? row.contactDetails.email : 'NA',
                                row.domain ? row.domain : 'NA',
                                row._created_at ? moment(row._created_at).format('MM/DD/YYYY') : 'NA'
                            ]);
                            yPos = addTable(doc, yPos, ['Name', 'Email', 'Domain', 'CreatedAt'], pdfData, ['Total Events'], [totalCount]);
                            savePdf('Events');
                        } else {
                            throw new Error('No Results Found!!');
                        }
                        break;

                    case 'Dau':
                        if (this.reportsList.length > 0) {
                            yPos = await addChart(doc, yPos);

                            const totalCount = this.reportsList.reduce((sum: any, report: any) => sum + (report.count || 0), 0);

                            const contactList = await convertData(this.reportsList, 'Dau')

                            const data = contactList.allRecors;

                            yPos = await addChart(doc, yPos);
                            const pdfData = data.map((row: any) => [
                                row.contactDetails && row.contactDetails.name ? row.contactDetails.name : 'NA',
                                row.contactDetails && row.contactDetails.email ? row.contactDetails.email : 'NA',
                                row.domain ? row.domain : 'NA',
                                row._created_at ? moment(row._created_at).format('MM/DD/YYYY') : 'NA'
                            ]);
                            yPos = addTable(doc, yPos, ['Name', 'Email', 'Domain', 'CreatedAt'], pdfData, ['Total DAU'], [totalCount]);
                            savePdf('DAU');
                        } else {
                            throw new Error('No Results Found!!');
                        }
                        break;

                    case 'NewSignUp':
                        if (this.reportsList.length > 0) {
                            yPos = await addChart(doc, yPos);
                            const pdfData = this.reportsList.map((signUp: any) => [
                                signUp.count || 'NA',
                                signUp.objectId && signUp.objectId.date ? moment(signUp.objectId.date).format('MM/DD/YYYY') : 'NA'
                            ]);
                            yPos = addTable(doc, yPos, ['Count', 'Created At'], pdfData, ['Total New SignUps'], [this.reportsList.length]);
                            savePdf('NewSignUp');
                        } else {
                            throw new Error('No Results Found!!');
                        }
                        break;

                    default:
                        throw new Error('Invalid report type selected');
                }
            } catch (error) {
                this.notifier.notify('error', error.message);
                this.showPdfContent = false;
            }
        } else {
            try {
                await this.captureChartImage()
                this.generatePDF();
            }catch (error) {
                this.notifier.notify('error', error.message);
                this.showPdfContent = false;
            }
        }
    }
    
    public renderTable = (dtOptions: any) => {
        // destroy you current configuration
        this.dtRendered = false;
        this.dtOptions = dtOptions;
        // make sure your template notices it
        this.cdr.detectChanges();
        // initialize them again
        this.dtRendered = true;
        this.dtTrigger = new Subject();
        this.cdr.detectChanges();
        this.dtTrigger.next();
    }

    public captureChartImage() {
        const element = document.querySelector('.reports-chart') as HTMLElement;
        if (element) {
            html2canvas(element).then(canvas => {
                this.chartImage = canvas.toDataURL('image/png');
                return;
            }).catch(error => {
                console.error('Error capturing chart image:', error);
                return;
            });
        } else {
            console.error('chart-container element not found.');
            return;
        }
    }
}

export type Csv = {
    customerName?: string,
    customerEmail?: string
    phoneNumber?: number,
    email?: string,
    name?: string,
    createdAt?: string,
    dealValue?: number,
    description?: string,
    status?: string,
    stage?: string,
}

export type Userdata = {
    name?: string,
    userType?: string,
    totalLeads?: number,
    totalRevenue?: number,
    totalDealValue?: number,
}

