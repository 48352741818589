import {ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {ModalDirective} from "ngx-bootstrap/modal";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

import * as moment from "moment";
// @ts-ignore
import D3Funnel from "d3-funnel";
import {AuthService} from "src/app/services/auth";
import {DataService} from "src/app/services/data.service";
import {UserService} from "src/app/services/user.service";
import {EventsService} from "../../services/events.service";
import {SharedDataService} from '../../services/shared-data.service';
import {Leads} from "src/app/types/leads";
import {User} from "src/app/types/user";
import {Spaces} from "src/app/types/spaces";
import {Stages} from "src/app/types/stages";
import {Contacts, ContactsParams} from "src/app/types/contacts";
import {Tasks} from "src/app/types/tasks";
import {TaskService} from "src/app/services/task.service";
import {NotifierService} from "angular-notifier";
import {BehaviorSubject, Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/internal/operators";
import {tz} from "moment-timezone";
import {validationPattern} from "../../constants/regexp";
import {ContactsService} from "../../services/contacts.service";
import {track} from '@inleads/event-logger';
import {ConfigService} from '../../services/config-data.service';
import {IcalService} from "../../services/ical.service";
import {environment} from "src/environments/environment";
import {HelperService} from '../../services/helper.service';
import {getTasksData} from "../../helpers/tasks.helper.data";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import {DashboardService} from "../../services/dashboard.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild("codeToWhatsup") codeToWhatsup: ElementRef;
  @ViewChild("addToWhatsup") addToWhatsup: ElementRef;
  @ViewChild("codeToContact") codeToContact: ElementRef;
  @ViewChild("addToContact") addToContact: ElementRef;
  @ViewChild("editTask") public editTaskModal?: ModalDirective;
  @ViewChild("newTask") public newTaskModal?: ModalDirective;
  @ViewChild("deleteTask") public deleteTaskModal?: ModalDirective;
  @ViewChild("funnelModal") public funnelModal?: ModalDirective;
  @ViewChild("companyProfile") public companyProfileModal?: ModalDirective;
  @ViewChild("contactModal") public contactModal: ModalDirective;
  @ViewChild("newlead") public newleadModal?: ModalDirective;
  @ViewChild("firstDataModal") public firstDataModal?: ModalDirective;
  @ViewChild("firstWidgetsModal") public firstWidgetsModal?: ModalDirective;
  public isLoading = true;
  public spaceList: Spaces[] = [];
  public stageList: Stages[] = [];
  public tasksList: Tasks[] = [];
  public leadsList: Leads[] = [];
  public contactsList: Contacts[] = [];
  public currentSpace: any;
  public profileImg: string;
  public editTaskForm: FormGroup;
  public newTaskForm: FormGroup;
  public contactForm: FormGroup;
  public currentUser: User | undefined;
  public templateType = "";
  public templateId = "";
  public templateDescription = "";
  public showGrowth = true;
  public showSales = false;
  public showCustomerExp = false;
  public funnelCreateClicked = false;
  public spaceNameModel: string = "";
  public newSpaceName: string = "Leads";
  public userName: string;
  public isNewUser: boolean = false;
  public modalConfig: any = { backdrop: true };
  public firstDataModalConfig: any = { backdrop: true };
  public firstWidgetsModalConfig: any = { backdrop: true };
  public spaceListLength: number;
  public funnelTemplates = [];
  public activityData: any[] = [];
  public companyProfileForm: FormGroup;
  public timezonesList: any;
  public createdSpaceId: any;
  public entityResponse: any;
  public isProfileCompleted: boolean;
  public disableFields: boolean;
  public leadsListData: any[] = [];
  public leadId: any;
  public stageId: any;
  public eventsApiKey: string;
  public currentMonthRetentionRate: number;
  public PreviousMonthRetentionRate: string;
  public eventsList: any[] = [];
  public configData: any;
  public whatsappWidgetHide:boolean = true
  public whatsappWidgetVisible:boolean = false
  public contactFormWidgetHide:boolean = true
  public contactFormWidgetVisible:boolean = false
  public connectFacebookHide:boolean = true
  public connectFacebookVisible:boolean = false
  public isButtonDisabled: boolean;
  public isPageLoaded: boolean;
  public totalLeads: any;
  public selectedMetric : any;
  public hideMetrics: boolean = false;
  public leadsObservableSubscription: any;
  public fbPages: string[] = [];
  public messagesCountVal : number = 0;
  fbNotIntegrated: boolean = false;
  public calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  public calendarTasksList: any = [];
  public scrollTime = new Date().toTimeString().split(' ')[0].substring(0, 5);
  public tasksModal: { visible: boolean, data?: any } = { visible: false };
  public selectedDate:  Date;
  public customHeader = {
    left: 'prev',
    center: 'title',
    right: 'next'
  };
  public dashboardEngagementMetrics: any[] = [];
  public currencyPrefix : string = '$' ;
  public taskStagesList : any =[];

  whatsappWidgetClick()
  {
    this.whatsappWidgetHide = !this.whatsappWidgetHide;
    this.whatsappWidgetVisible = !this.whatsappWidgetVisible;
    this.connectFacebookVisible = false;
    this.contactFormWidgetVisible = false;
  }
  contactFormWidgetClick() {
    this.contactFormWidgetHide = !this.contactFormWidgetHide;
    this.contactFormWidgetVisible = !this.contactFormWidgetVisible;
    this.connectFacebookVisible = false;
    this.whatsappWidgetVisible = false;
  }
  connectFacebookClick() {
    this.connectFacebookHide = !this.connectFacebookHide;
    this.connectFacebookVisible = !this.connectFacebookVisible;
    this.contactFormWidgetVisible = false;
    this.whatsappWidgetVisible = false;
  }

  copyWhatsupCode() {
    const textArea = this.codeToWhatsup.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyAddWhatsupCode() {
    const textArea = this.addToWhatsup.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyContactCode() {
    const textArea = this.codeToContact.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyAddContactCode() {
    const textArea = this.addToContact.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }

  public count = {
    leads: 0,
    contacts: 0,
    deals: 0,
    revenue: 0,
    dau: 0,
    prevDau: 0,
    currentChurn: 0,
    previousChurn: 0,
    dauPercentage: 0,
    churnPercentage: 0,
    retentionRatePercentage: 0,
    dauArrow: 'no-change',
    arrow: "no-change",
    arrowR: "no-change",
    churnRateChange: "no-change",
    retentionRateChange: "no-change",
  };

  count$ = new BehaviorSubject<object>(this.count);

  public colorCodes = [
    "#dbb1f1",
    "#cc84f2",
    "#e758d5",
    "#c018ab",
    "#4f078d",
    "#060d53",
  ];
  public charts: any = {
    funnel: {
      title: "Total Leads",
      type: "funnelChart",
      data: [],
      options: {
        block: {
          dynamicHeight: false,
          minHeight: 15,
        },
        label: {
          format: "{f}",
        },
      },
      width: 800,
      height: 350,
    },
    monthly: {
      title: "Monthly Leads",
      type: "BarChart",
      data: [] as any,
      columnNames: ["Month"],
      options: {
        hAxis: {
          title: "Month",
        },
        vAxis: {
          minValue: 0,
        },
        isStacked: true,
        colors: this.colorCodes,
      },
      width: 360,
      height: 250,
    },
    target: {
      title: "Monthly Target",
      type: "PieChart",
      data: [] as any,
      columnNames: ["Deal Value", "Target Value"],
      options: {
        is3D: true,
      },
      width: 400,
      height: 285,
    },
    AUBrowserMetrics: {
      title: "Events",
      type: "PieChart",
      data: [] as any,
      columnNames: ['Browser', 'count',],
      options: {
        colors: this.colorCodes,
      },
      width: 400,
      height: 285,
    },
    AUByCountryMetrics: {
      type: "ColumnChart",
      data: [] as any,
      columnNames: ["Event Name", "Total Events"],
      options: {
        hAxis: {
          title: "Event Name",
        },
        vAxis: {
          title: "Total Events",
          minValue: 0,
        },
        isStacked: true,
        colors: ["#1B1464"],
        legend: { position: 'none' }
      },
      width: 400,
      height: 285,
    },
  };
  public activeUsersBrowserSessionsList : any = [];
  public activeUsersByCountryList : any = [];
  public dealAmount = {
    total: 0,
    target: 0,
    won: 0,
    lost: 0,
    monthly: 0,
    pipeline: 0,
  };
  public financialData = {
    mrr: 0,
    arr: 0,
  };
  public isChartLoaded = false;
  public hasFunnelChartData = true;
  public currentTaskObjectID: string;
  public opt: never[];
  public currentLead: any;
  public currentNewTaskLead: string;
  public currentLeadStage: string;
  public selectedSpace: any;
  public sharedSpaceId: string;
  public destroyed = new Subject<any>();
  public addressComponents = {
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  };
  public imgURL: any;
  public currentContact: string;
  public profilePhotoLoader: boolean;
  public formattedaddress: string;
  public contactList: Contacts[] = [];
  public followupData: any = {};
  public isEmptyState: boolean;
  public standUpData: any = {
    question1: {
      question: "",
      answer: "",
      questionId: "",
      followupRespId: "",
    },
  };
  public currentQuestionData = {
    index: 1,
    questionId: "",
    answer: this.standUpData.question1.answer,
  };
  public followUpId: string;
  public followUpCount: number = 0;
  public isSubmitted: boolean;
  public status = {
    wonId: "lGXBCm91gj",
    lostId: "tt4nl8dJg6",
  };
  @ViewChild("standUpModal") public standUpModal?: ModalDirective;
  public isAdmin: boolean;
  public isSuperAdmin: boolean;
  public totalRevenue: number = 0;

  constructor(
      public userService: UserService,
      private fb: FormBuilder,
      public authService: AuthService,
      public contactService: ContactsService,
      private configService: ConfigService,
      private taskService: TaskService,
      private notifier: NotifierService,
      public dataService: DataService,
      public eventService: EventsService,
      private router: Router,
      private route: ActivatedRoute,
      @Inject(LOCALE_ID) public locale: string,
      private icalService: IcalService,
      private cdr: ChangeDetectorRef,
      private helperService: HelperService,
      private sharedDataService: SharedDataService,
      private dashboardService: DashboardService,
  ) {}

  public async ngOnInit() {
    this.isAdmin = this.authService.isAdmin();
    this.isSuperAdmin = this.authService.isSuperAdmin();
    this.hideMetrics = this.authService.isCSPUser();
    const user = localStorage.getItem("user");
    const currentUser = user ? JSON.parse(user) : {};
    const entityData = localStorage.getItem('entity');
    let {currency} = entityData ? JSON.parse(entityData) : null;
    this.currencyPrefix = currency ? currency: '$';
    localStorage.setItem("currencyPrefix", this.currencyPrefix);
    const companyName = currentUser && currentUser.companyName ? currentUser.companyName : '';
    track("HOME");
    this.getTasksStagesList();
    // Form
    this.contactForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(validationPattern.email),
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(validationPattern.phone),
      ]),
      gender: new FormControl("", [Validators.required]),
      address: new FormControl(""),
      /* pincode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),*/
    });
    this.companyProfileForm = this.fb.group({
      companyName: [companyName, Validators.required],
      // profileFormUrl: ['', Validators.required],
      // entityPhoneNumber: ['',],
      companyBio: ['', Validators.required],
      timeZone: [
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        Validators.required,
      ],
      salesTarget: ["1000", Validators.required],
      currency: ["$", Validators.required],
      northMetric: ["DAU", Validators.required],
      businessModel: ["saas", Validators.required],
    });
    this.timezonesList = tz.names();
    this.route.params.subscribe(async (params) => {
      if (Object.keys(params).length > 0) {
        this.sharedSpaceId = params.id;
        if (this.sharedSpaceId === "newSpace" || !this.sharedSpaceId) {
          this.fetchDashboardData();
        } else if (this.sharedSpaceId) {
          this.authService.saveData(this.sharedSpaceId);
          this.fetchDashboardData(this.sharedSpaceId);
        }
      }
    });
    this.configData = await this.configService.getConfigData();
    this.router.events
    .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed)
    )
    .subscribe((res: any) => {
      if (res && res.url.includes("/newSpace")) {
        this.showFunnelModal();
        this.fetchDashboardData();
      }
    });

    this.renderData();
    this.getTotalEmailCount();

    this.isLoading = false;

    // Leads live query
    if (this.leadsObservableSubscription) this.leadsObservableSubscription.unsubscribe();
    this.leadsObservableSubscription = this.helperService.getLiveQueryLeadsData().subscribe(async () => {
      this.renderData();
      this.getDashboardNumbers();
    });

    await this.checkIsFbIntegrated();
    this.getActivity();
    try {
      const resp = await this.dataService.postToServer(
       'functions/get-yearly-transaction-sum', {});
       this.totalRevenue = resp && resp.result ? resp.result : 0;
     } catch (e) {
       console.log('error ',e)
     }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    if (this.leadsObservableSubscription) this.leadsObservableSubscription.unsubscribe();
  }

  public async fetchDashboardData(spaceId?: string) {
    this.isLoading = true;
    const user = localStorage.getItem("user");
    this.profileImg = "assets/images/user-01.svg";
    // this.getTodaysFollowUpsResponse();
    if (user) {
      const currentUser = JSON.parse(user);
      this.userName = currentUser.name;
      await this.dataService.updateSessionHeaders(currentUser.sessionToken);
      if (currentUser.target != null) {
        this.dealAmount.target = Number(currentUser.target);
        setTimeout(()=>{
          this.count$.next({ ...this.count });
        },100)
      }
      this.currentUser = this.userService.getUser();
    }
    this.isProfileCompleted = await this.getEntityData();
    // this.getLeadsForTasks();
    this.getTaskList().then();
    let query: any;
    let query2: any;
    if (!this.authService.isSuperAdmin()) {
      query = {
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId,
          },
          isArchived: { $ne: true },
        },
      };
      if (spaceId) {
        query.where["objectId"] = spaceId;
      }
    }
    if (!this.authService.isSuperAdmin()) {
      query2 = {
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId,
          },
          space: { $exists: true }, 
          isArchived:  { $ne: true }
        },
      };
      if (spaceId) {
        query2.where.space = {
          __type: "Pointer",
          className: "Spaces",
          objectId: spaceId,
        };
      }
    }
    // const spaceResp = await this.dataService.getFromServer(
    //     "classes/Spaces",
    //     query
    // );
    const stageResp = await this.dataService.getFromServer(
        "classes/Stages",
        query2
    );
    this.stageList = stageResp.results;
    // this.spaceList =
    //     spaceResp.results && spaceResp.results.length > 0
    //         ? [spaceResp.results[0]]
    //         : [];
    this.spaceListLength = this.spaceList.length;
    this.isNewUser = this.spaceListLength == 0;
    if (this.spaceListLength == 0 && this.authService.isAdmin()) {
      await this.fetchFunnelTemplates();
      this.showFunnelModal();
      localStorage.setItem("showTour", "true");
    }
    if (!this.isProfileCompleted && this.spaceListLength > 0) {
      this.companyProfileModal!.show();
    }
    this.modalConfig = {
      backdrop: this.spaceListLength == 0 ? "static" : true,
    };
    this.selectedSpace = this.spaceList[0];
    this.charts.monthly.columnNames = ["Month"];
    this.charts.monthly.columnNames = this.charts.monthly.columnNames.concat(
        this.stageList.map((x: any) => x.name)
    );
    this.getDashboardNumbers();
    this.editTaskForm = this.fb.group({
      editTaskName: ["", Validators.required],
      editTaskType: ["", Validators.required],
      edit_task_nextActivity: [new Date(), Validators.required],
      edit_task_activityNotes: [""],
      editTaskStage: ['', Validators.required],
    });

    this.newTaskForm = this.fb.group({
      newTaskName: ["", Validators.required],
      newTaskType: ["", Validators.required],
      new_task_nextActivity: [new Date(), Validators.required],
      new_task_activityNotes: [""],
      task_lead: ["", Validators.required],
    });
  }

  ngAfterViewInit() {
    this.isNewUser = this.spaceListLength == 0;
    this.modalConfig = {
      backdrop: this.spaceListLength == 0 ? "static" : true,
    };
    if (this.sharedSpaceId === "newSpace") {
      this.showFunnelModal();
    }
    this.isPageLoaded=true;
    this.cdr.detectChanges();
  }

  private async renderData() {
    this.getActiveUsersByCountryMetaData();

    this.eventsList = await this.eventService.getEventsList(false);

    this.dashboardService.getDashboardData('lastTenDays', {callGetEngagedUsersMetrics: true}).subscribe((data: any) => {
      this.dashboardEngagementMetrics = data.graphDate;
    });

    this.dashboardService.getDashboardData('thisYear', {callDailyUsers: true}).subscribe((data: any) => {
      this.count.dau = data.dailyActiveUsers || 0;
      this.count.currentChurn = data.churnRate || 0;
      this.currentMonthRetentionRate = data.retentionRate || 0;

      // AVG DAU
      this.count.dauArrow =
          data.dailyActiveUsers > data.prevDau ? 'up' :
              data.dailyActiveUsers < data.prevDau ? 'down' :
                  'no-change';

      if (data.prevDau === 0) {
        this.count.dauPercentage = data.dailyActiveUsers > 0 ? 100 : 0;
      } else {
        this.count.dauPercentage = data.dailyActiveUsers === 0 ? 100 :
            parseInt(String(Math.round(((data.dailyActiveUsers - data.prevDau) / data.prevDau) * 100)));
      }

      // AVG CHURN
      let arrow;
      if (data.churnRate > data.previousChurn) {
        arrow = "up";
      } else if (data.churnRate < data.previousChurn) {
        arrow = "down";
      } else {
        arrow = "no-change";
      }
      this.count.arrow = arrow;

      if (data.previousChurn === 0) {
        this.count.currentChurn = data.churnRate > 0 ? 100 : 0;
      } else {
        this.count.churnPercentage = data.churnRate === 0 ? 100 :
            parseInt(String(Math.round(((data.churnRate - data.previousChurn) / data.previousChurn) * 100)));
      }

      //current month retention rate
      let currentMonthRetentionRate = 1 - (data.churnRate / 100);

      //previous month retention rate
      let PreviousMonthRetentionRate = 1 - (data.previousChurn / 100);

      //previous month retention rate value
      this.count.retentionRateChange = `${this.PreviousMonthRetentionRate}% last month`;

      //retention rate trend arrow
      let arrowR;
      if (currentMonthRetentionRate > PreviousMonthRetentionRate) {
        arrowR = "up";
      } else if (currentMonthRetentionRate < PreviousMonthRetentionRate) {
        arrowR = "down";
      } else {
        arrowR = "no-change";
      }
      this.count.arrowR = arrowR;

      if (PreviousMonthRetentionRate === 0) {
        this.count.retentionRatePercentage = currentMonthRetentionRate > 0 ? 100 : 0;
      } else {
        this.count.retentionRatePercentage = currentMonthRetentionRate === 0 ? 100 :
            parseInt(String(Math.round(((currentMonthRetentionRate - PreviousMonthRetentionRate) / PreviousMonthRetentionRate) * 100)));
      }
    });

    // this.dashboardService.getDashboardData('thisYear', {callGetContacts: true}).subscribe((data: any) => {
    //   this.count.contacts = data.totalContacts || 0;
    // });
    
    const totalContactsData = await this.getContacts();
    this.count.contacts = totalContactsData;

    const spaces = await this.getSpaceList();
    
    const totalLeadsData = await this.fetchLeadsListData();           
    this.count.leads = totalLeadsData.length || 0;
    const totalDeal = totalLeadsData.reduce((sum:any, lead:any) => sum + (lead.dealValue || 0), 0);
      this.dealAmount.total = totalDeal || 0;
      this.spaceList = spaces;
      this.leadsListData = totalLeadsData;
      this.leadsList = totalLeadsData;
      this.fetchDashboardData();


  //   this.dashboardService.getDashboardData('thisYear', {callFetchLeadsList: true}).subscribe((data: any) => {
  //     this.count.leads = data.totalLeads || 0;
  //     this.dealAmount.total = data.expectedRevenue || 0;
  //     this.spaceList = data.spaceList;
  //     this.leadsListData = data.allLeads;
  //     this.leadsList = data.allLeads;
  //     this.fetchDashboardData();
  //   });
   }

  public getActiveUsersByCountryMetaData = async () => {
    const rsp = await this.dataService.postToServer(
        "functions/getActiveUsersMetaData",
        {
          entityId: this.authService.getUser().entityId.objectId,
          groupByParam: 'name'
        }
    );
    this.activeUsersBrowserSessionsList = rsp;
    this.activeUsersByCountryList = rsp;
  };

  // public getTodaysFollowUpsResponse = async () => {
  //   const startDate = moment()
  //       .subtract(30, "d")
  //       .toDate()
  //       .toISOString();
  //   const endDate = moment()
  //       .endOf("day")
  //       .toDate()
  //       .toISOString();
  //   const userParams: any = {
  //     entityId: this.authService.getUser().entityId.objectId,
  //     startDate,
  //     endDate,
  //   };
  //   const followUpResponseList = await this.dataService.postToServer(
  //       "functions/getFollowUpResponse",
  //       userParams
  //   );
  //   if (followUpResponseList.result.length) {
  //     let self = this;
  //     const followUpDataGroupedByDate = _.groupBy(
  //         followUpResponseList.result,
  //         function(obj) {
  //           return self.dateToFromNowDaily(moment(obj.createdAt));
  //         }
  //     );
  //     this.formatFollowUp(followUpDataGroupedByDate);
  //     this.isEmptyState = false;
  //   } else {
  //     this.isEmptyState = true;
  //   }
  // };

  // private formatFollowUp = (data: any) => {
  //   if (Object.keys(data).length) {
  //     for (const [key, value] of Object.entries(data)) {
  //       let data: any = value;
  //       const followUpDataGroupedByName = _.groupBy(data, function(obj) {
  //         return obj.user.name;
  //       });
  //       this.followupData[key] = followUpDataGroupedByName;
  //     }
  //   }
  // };

  // call this function, passing-in your date
  public dateToFromNowDaily = (myDate: any) => {
    let date = moment(myDate);
    if (moment().diff(date, "days") > 0) {
      return moment(myDate).format("DD/MM/YYYY");
    }
    return date.calendar().split(" ")[0];
  };

  public hideConnectSDKModal() {
    this.firstDataModal!.hide();
    this.hasFunnelChartData = true;
    this.router.navigateByUrl("/leads/" + this.createdSpaceId, {
      replaceUrl: true,
    });
  }

  public gotoEventLoggerModal() {
    this.firstWidgetsModal!.hide();
    this.firstDataModal!.show();
  }

  public async validateDataAndSave() {
    const activityParams = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
      },
    };
    const events = await this.dataService.getFromServer(
        'classes/Events',
        activityParams,
    );
    if (events.results && events.results.length > 0) {
      this.firstDataModal!.hide();
      this.hasFunnelChartData = true;
      this.router.navigateByUrl("/leads/" + this.createdSpaceId, {
        replaceUrl: true,
      });
    } else{
      this.notifier.notify('error', 'Events not detected. Please follow steps above to connect.');
    }
  }

  public async getEntityData() {
    this.entityResponse = await this.dataService.getFromServer(
        "classes/Entity/" + this.authService.getUser().entityId.objectId
    );
    if (this.entityResponse && this.entityResponse.eventsApiKey === undefined) {
      this.updateEventsApiKey();
    } else {
      this.eventsApiKey = this.entityResponse.eventsApiKey;
    }
    return this.entityResponse.isProfileCompleted;
  }

  public async copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.innerText = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  public async updateEventsApiKey() {
    try {
      const response = await this.dataService.postToServer(
          "functions/updateEventsApiKey",
          {}
      );
      this.eventsApiKey = response.result;
    } catch (e) {
      this.notifier.notify("error", "Unable to update Api key");
    }
  }

  public async showFunnelModal() {
    this.funnelModal!.show();
  }

  public async editTasks(lead: Leads) {
    this.opt = [];
    const taskParams = {
      where: {
        stage: {
          __type: "Pointer",
          className: "Stages",
          objectId: lead!.stage!.objectId,
        },
        lead: {
          __type: "Pointer",
          className: "Leads",
          objectId: lead!.objectId,
        },
        isArchive: { $ne: true },
      },
      include: "stage,lead,lead.contact",
      order: "createdAt",
    };
    let tasks = await this.dataService.getFromServer(
        "classes/Tasks",
        taskParams
    );
    tasks = tasks && tasks.results;
    this.opt =
        tasks &&
        tasks.filter((x: Tasks) => {
          if (tasks.length === 0) {
            return x.stage!.name === "Nurturing";
          } else {
            return x.stage!.name === tasks[0].stage.name;
          }
        });
    return true;
  }

  public async onTaskChange(taskId: string) {
    if (taskId) {
      const taskParams = {
        isComplete: true,
      };
      await this.dataService.updateToServer(
          "classes/Tasks/" + taskId,
          taskParams
      );
      this.tasksModal.visible = false;
      this.notifier.notify("success", "Task Marked as Completed");
      await this.getTaskList();
    }
  }

  public async updateTask(addToCalendar?: boolean) {
    const name = this.editTaskForm.get("editTaskName")!.value;
    const taskType = this.editTaskForm.get("editTaskType")!.value;
    const taskNextActivity = this.editTaskForm.get("edit_task_nextActivity")!
        .value;
    const taskActivityNotes = this.editTaskForm.get("edit_task_activityNotes")!
        .value;
   const editTaskStage = this.editTaskForm.get('editTaskStage')!.value;

    if (!name) {
      return this.notifier.notify("error", "Please Enter Name");
    }
    if (!taskType) {
      return this.notifier.notify("error", "Please Select Task Type");
    }
    if (!taskNextActivity) {
      return this.notifier.notify("error", "Please Enter Next Activity");
    }

    const taskParams: any = {
      stageOption: name,
      taskType,
      nextActivity: {
        __type: "Date",
        iso: new Date(taskNextActivity),
      },
      activityNotes: taskActivityNotes,
      stage :{
        __type : 'Pointer',
        className: 'Stages',
        objectId: editTaskStage,
      },
    };
    const id = this.currentTaskObjectID;
    this.isButtonDisabled = true;
    this.editTaskModal!.hide();
    await this.taskService.updateTask(taskParams, id);

    // If addToCalendar is true, add the task to the calendar
    if (addToCalendar) {
      const data = await this.icalService.parseIcal(taskNextActivity, name, taskActivityNotes);
      const blob = new Blob([data], {type: 'text/calendar'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'calendar.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
    this.notifier.notify("success", "Task Updated Successfully!!");
    await this.getTaskList();
  }

  public async createTask(isAddToCalendar?: boolean) {
    const name = this.newTaskForm.get("newTaskName")!.value;
    const taskType = this.newTaskForm.get("newTaskType")!.value;
    const taskNextActivity = this.newTaskForm.get("new_task_nextActivity")!
        .value;
    const taskActivityNotes = this.newTaskForm.get("new_task_activityNotes")!
        .value;
    let leadData = this.newTaskForm.get("task_lead")!.value;
    leadData = leadData.split(":");
    this.leadId = leadData[0];
    this.stageId = leadData[1];
    if (!name) {
      return this.notifier.notify("error", "Please Enter Name");
    }
    if (!taskType) {
      return this.notifier.notify("error", "Please Select Task Type");
    }
    if (!taskNextActivity) {
      return this.notifier.notify("error", "Please Enter Next Activity");
    }
    if (!this.leadId) {
      return this.notifier.notify("error", "Please Select Lead");
    }

    const taskParams: any = {
      objectId: "",
      stageOption: name,
      taskType,
      nextActivity: {
        __type: "Date",
        iso: new Date(taskNextActivity),
      },
      activityNotes: taskActivityNotes,
      lead: {
        __type: "Pointer",
        className: "Leads",
        objectId: this.leadId,
      },
      stage: {
        __type: "Pointer",
        className: "Stages",
        objectId: this.stageId,
      },
      entity: {
        __type: "Pointer",
        className: "Entity",
        objectId: this.currentUser!.entityId!.objectId,
      },
      isComplete:false
    };
    this.isButtonDisabled = true;
    this.newTaskModal!.hide();
    await this.taskService.addTask(taskParams);
    if(taskNextActivity && !!isAddToCalendar) {
      const data = await this.icalService.parseIcal(taskNextActivity, name, taskActivityNotes);
      const blob = new Blob([data], {type: 'text/calendar'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'calendar.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url)
    }
    this.notifier.notify("success", "Task created Successfully!!");
    await this.getTaskList();
  }

  public async changeSpace(space: Spaces) {
    this.charts.funnel.title = "Total Leads";
    this.charts.funnel.data = [];
    let stageList = this.stageList.filter(
        (x: any) => x.space && space && x.space.objectId === space!.objectId
    );
    stageList = stageList.sort((a: any, b: any) => a.order - b.order);
    stageList.forEach((stage: any, i: number) => {
      const funObj = {
        label: stage.name,
        probabilityValue: stage.probabilityValue ? stage.probabilityValue : 100,
        value: 0,
        backgroundColor: this.colorCodes[i],
        numberofLeads: 0,
      };
      this.charts.funnel.data.push(funObj);
    });
    if (stageList.length > 0) {
      this.hasFunnelChartData = true;
      this.setGraphs();
    } else {
      this.hasFunnelChartData = false;
    }
  }

  // public async fetchLeadsList() {
  //   const getCurrentUser = this.authService.getUser();
  //   let params: any;
  //   if (this.authService.isSuperAdmin()) {
  //     params = {
  //       include: "status,stage,contact",
  //       where: {
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   } else if (this.authService.isAdmin()) {
  //     params = {
  //       include: "status,stage,contact",
  //       order: "-dealValue",
  //       where: {
  //         entity: {
  //           __type: "Pointer",
  //           className: "Entity",
  //           objectId: this.authService.getUser().entityId.objectId,
  //         },
  //         status: {
  //           $ne: {
  //             __type: "Pointer",
  //             className: "Status",
  //             objectId: "tt4nl8dJg6",
  //           },
  //         },
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   } else if (this.authService.isAgent()) {
  //     params = {
  //       include: "status,stage,contact",
  //       where: {
  //         entity: {
  //           __type: "Pointer",
  //           className: "Entity",
  //           objectId: this.authService.getUser().entityId.objectId,
  //         },
  //         status: {
  //           $ne: {
  //             __type: "Pointer",
  //             className: "Status",
  //             objectId: "tt4nl8dJg6",
  //           },
  //         },
  //         isArchived: { $ne: true },
  //       },
  //     };
  //     if(!!getCurrentUser.isPrivate) {
  //       params = {
  //         where: {
  //           ...params.where,
  //           $or: [
  //             {
  //               agent: {
  //                 __type: 'Pointer',
  //                 className: '_User',
  //                 objectId: this.authService.getUser().objectId,
  //               },
  //             },
  //             {
  //               assignTo: {
  //                 __type: 'Pointer',
  //                 className: '_User',
  //                 objectId: this.authService.getUser().objectId,
  //               },
  //             },
  //           ],
  //         },
  //       }
  //     }
  //   } else if (this.authService.isManager()) {
  //     params = {
  //       include: "status,stage,contact",
  //       where: {
  //         $or: [
  //           {
  //             agent: {
  //               $inQuery: {
  //                 where: {
  //                   manager: {
  //                     __type: "Pointer",
  //                     className: "_User",
  //                     objectId: this.authService.getUser().objectId,
  //                   },
  //                 },
  //                 className: "_User",
  //               },
  //             },
  //           },
  //           {
  //             assignTo: {
  //               $inQuery: {
  //                 where: {
  //                   manager: {
  //                     __type: "Pointer",
  //                     className: "_User",
  //                     objectId: this.authService.getUser().objectId,
  //                   },
  //                 },
  //                 className: "_User",
  //               },
  //             },
  //           },
  //           {
  //             agent: {
  //               __type: "Pointer",
  //               className: "_User",
  //               objectId: this.authService.getUser().objectId,
  //             },
  //           },
  //           {
  //             assignTo: {
  //               __type: "Pointer",
  //               className: "_User",
  //               objectId: this.authService.getUser().objectId,
  //             },
  //           },
  //         ],
  //         status: {
  //           $ne: {
  //             __type: "Pointer",
  //             className: "Status",
  //             objectId: "tt4nl8dJg6",
  //           },
  //         },
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   }
  //   if (this.selectedSpace) {
  //     params.where.space = {
  //       __type: "Pointer",
  //       className: "Spaces",
  //       objectId: this.selectedSpace.objectId,
  //     };
  //   }
  //   params.limit = 10000;
  //   const leadsResp = await this.dataService.getFromServer(
  //       "/classes/Leads",
  //       params
  //   );
  //   return leadsResp;
  // }

  // public async fetchOpenLeadsList() {
  //   let params: any;
  //   if (this.authService.isSuperAdmin()) {
  //     params = {
  //       include: "status,stage,contact",
  //       limit:10000,
  //       where: {
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   } else if (this.authService.isAdmin()) {
  //     params = {
  //       include: "status,stage,contact",
  //       order: "-dealValue",
  //       limit:10000,
  //       where: {
  //         entity: {
  //           __type: "Pointer",
  //           className: "Entity",
  //           objectId: this.authService.getUser().entityId.objectId,
  //         },
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   } else if (this.authService.isAgent()) {
  //     params = {
  //       include: "status,stage,contact",
  //       limit:10000,
  //       where: {
  //         $or: [
  //           {
  //             agent: {
  //               __type: "Pointer",
  //               className: "_User",
  //               objectId: this.authService.getUser().objectId,
  //             },
  //           },
  //           {
  //             assignTo: {
  //               __type: "Pointer",
  //               className: "_User",
  //               objectId: this.authService.getUser().objectId,
  //             },
  //           },
  //         ],
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   } else if (this.authService.isManager()) {
  //     params = {
  //       include: "status,stage,contact",
  //       limit:10000,
  //       where: {
  //         $or: [
  //           {
  //             agent: {
  //               $inQuery: {
  //                 where: {
  //                   manager: {
  //                     __type: "Pointer",
  //                     className: "_User",
  //                     objectId: this.authService.getUser().objectId,
  //                   },
  //                 },
  //                 className: "_User",
  //               },
  //             },
  //           },
  //           {
  //             assignTo: {
  //               $inQuery: {
  //                 where: {
  //                   manager: {
  //                     __type: "Pointer",
  //                     className: "_User",
  //                     objectId: this.authService.getUser().objectId,
  //                   },
  //                 },
  //                 className: "_User",
  //               },
  //             },
  //           },
  //           {
  //             agent: {
  //               __type: "Pointer",
  //               className: "_User",
  //               objectId: this.authService.getUser().objectId,
  //             },
  //           },
  //           {
  //             assignTo: {
  //               __type: "Pointer",
  //               className: "_User",
  //               objectId: this.authService.getUser().objectId,
  //             },
  //           },
  //         ],
  //         isArchived: { $ne: true },
  //       },
  //     };
  //   }
  //   if (this.selectedSpace) {
  //     params.where.space = {
  //       __type: "Pointer",
  //       className: "Spaces",
  //       objectId: this.selectedSpace.objectId,
  //     };
  //   }
  //
  //   const leadsResp = await this.dataService.getFromServer(
  //       "/classes/Leads",
  //       params
  //   );
  //   return leadsResp;
  // }

  public async getDashboardNumbers() {
    // const openLeadsResp = await this.fetchOpenLeadsList();
    // this.count.leads = openLeadsResp.results.length;
    // this.sharedDataService.changeLeads(this.count.leads)
    // await this.getContacts();

    // setTimeout(()=>{
    //   this.count$.next({ ...this.count });
    // },100)

    // const leadsResp = await this.fetchLeadsList();

    // this.leadsList = leadsResp.results;
    await this.changeSpace(this.selectedSpace);
  }


  public async getContacts() {
    const getCurrentUser = this.authService.getUser();
    let customerParams;
    if(this.authService.isSuperAdmin()){
      customerParams = {
        include: 'agent',
        limit: 10000,
        order: '-createdAt',
        where: {
          email:{ $ne :'anonymous@inleads.ai'},
          isActive: true,
        },
      };
    } else if(this.authService.isAdmin()){
      customerParams = {
        include: 'agent',
        limit: 10000,
        order: '-updatedAt',
        where: {
          // isActive: !this.showDeactivatedContacts,
          isActive: { $ne : false },
          email:{ $ne :'anonymous@inleads.ai'},
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    } else if(this.authService.isAgent()){
      customerParams = {
        where: {
          isActive: true,
          email:{ $ne :'anonymous@inleads.ai'},
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
        include: 'agent',
        limit: 10000,
        order: '-createdAt',
      };
      if(!!getCurrentUser.isPrivate) {
        customerParams = {
          where: {
            ...customerParams.where,
            $or: [
              {
                agent: {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.authService.getUser().objectId,
                },
              },
              {
                assignTo: {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.authService.getUser().objectId,
                },
              },
            ],
          },
        }
      }
    } else if (this.authService.isManager()) {
      const parameters = {
        where: {
          isActive: true,
          manager: {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authService.getUser().objectId,
          },
        },
      };
      const userResponse = await this.dataService.getFromServer(
        'classes/_User',
        parameters,
      );
      const agentsList: {
        __type: string;
        className: string;
        objectId: string;
      }[] = [];
      userResponse.results.forEach((user: User) => {
        agentsList.push({
          __type: 'Pointer',
          className: '_User',
          objectId: user.objectId,
        });
      });
      agentsList.push({
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      });
      customerParams = {
        include: 'agent',
        limit: 10000,
        order: '-createdAt',
        where: {
          isActive: true,
          $or: [
            { agent: { $in: agentsList } },
            { assignTo: { $in: agentsList } },
          ],
        },
      };
    }
    const contactResponse = await this.dataService.getFromServer(
      'classes/Contacts',
      customerParams,
    );

    this.contactsList = contactResponse ? contactResponse.results : [];
    this.count.contacts =
        contactResponse && contactResponse.results
            ? contactResponse.results.length
            : 0;
    //this.sharedDataService.changeContacts(this.count.contacts);
    setTimeout(()=>{
      this.count$.next({ ...this.count });
    },100)
    return this.count.contacts;       

  
  }

  public async deleteCurrentTask() {
    const taskParams: any = {
      isArchive: true,
    };
    const id = this.currentTaskObjectID;
    await this.taskService.updateTask(taskParams, id);
    this.notifier.notify("success", "Task Archived Successfully!!");
    this.deleteTaskModal!.hide();
    await this.getTaskList();
  }

  public async openNewTaskPopUp(task: any) {
    this.currentNewTaskLead = task.lead.objectId || "";
    this.currentLeadStage = task.stage.objectId || "";
    this.newTaskModal!.show();
  }

  public async openEditTaskPopUp(task: Tasks) {
    this.tasksModal.visible = false;
    this.currentTaskObjectID = task.objectId || "";
    this.editTaskForm.patchValue({
      editTaskName: task.stageOption,
      edit_task_nextActivity: task.nextActivity
          ? new Date(task.nextActivity.iso)
          : new Date(),
      edit_task_activityNotes: task.activityNotes,
      editTaskType: task.taskType,
      editTaskStage : task.stage? task.stage.objectId : ''

     // editTaskStage : task.stage!.objectId
    });
    this.editTaskModal!.show();
  }

  public async openDeleteTaskPopup(task: Tasks) {
    this.tasksModal.visible = false;
    this.deleteTaskModal!.show();
    this.currentTaskObjectID = task.objectId || "";
  }

  handleEventClick(info: any) {
    this.tasksModal = { visible: true, data: info.event.extendedProps };
  }

  public navigateToLeadDetails(leadId: string) {
    this.router.navigate(['/lead-details', { leadId }]);
  }

  handleDateClick(arg: any) {
    const date = arg.dateStr;
    if(date) {
      this.selectedDate = date
      this.newTaskModal!.show();
      if (this.selectedDate) {
        this.newTaskForm.get('new_task_nextActivity')!.setValue(new Date(this.selectedDate));
      } else {
        this.newTaskForm.get('new_task_nextActivity')!.setValue(new Date());
      }
    }
  }

  public async calendarIcs(task: Tasks) {
    if (task && task.nextActivity && task.nextActivity!.iso) {
      const name = task.stageOption || '';
      const note = task.activityNotes || ''
      const data = await this.icalService.parseIcal(task.nextActivity!.iso, name, note);
      const blob = new Blob([data], {type: 'text/calendar'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'calendar.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }

  calendarHeight() {
    return 300;
  }

  onWindowResize() {
    this.calendarHeight()
  }

  public tasksModalClose() {
    this.tasksModal.visible = false;
  }

  public async showSpecificContact(contactID: string) {
    this.router.navigate(["/contact-details"], {
      state: { contactObjectId: contactID, isView: true },
    });
  }

  public setGraphs() {
    this.dealAmount.total = 0;
    this.count.deals = 0;
    this.dealAmount.won = 0;
    this.dealAmount.lost = 0;
    this.totalLeads = 0;
    this.leadsList.forEach((lead: Leads) => { 
      this.count.deals =
          lead.status && lead.status.name === "Won"
              ? this.count.deals + 1
              : this.count.deals;
       if (lead.status && lead.status.name === "Won") {
        this.dealAmount.won = lead.dealValue
            ? this.dealAmount.won + lead.dealValue
            : this.dealAmount.won;
      }
      setTimeout(()=>{
        this.count$.next({ ...this.count });
      },100)
      if (lead.stage) {
        const j = this.charts.funnel.data.findIndex(
            (x: any) => x.label === lead.stage!.name
        );
        if (lead && j > -1) {
          if(lead.dealValue && !lead.status) {
            this.charts.funnel.data[j].value =
                this.charts.funnel.data[j].value + lead.dealValue;
            this.dealAmount.total = this.dealAmount.total + lead.dealValue;
            this.sharedDataService.changeDealAmount(this.dealAmount.total);
          }
          this.charts.funnel.data[j].numberofLeads = this.charts.funnel.data[j].numberofLeads + 1;
        }
        // check moment.months
        let i = this.charts.monthly.data.findIndex(
            (x: any) => x[0] === moment(lead.createdAt).format("MMMM")
        );
        if (i < 0) {
          const arr = [moment(lead.createdAt).format("MMMM")].concat(
              Array(this.stageList.length).fill(0)
          );
          this.charts.monthly.data.push(arr);
          i = this.charts.monthly.data.length - 1;
        }
        if (i > -1) {
          const z = this.charts.monthly.columnNames.findIndex(
              (x: any) => x === lead.stage!.name
          );
          if (lead.dealValue !== null && lead.dealValue !== undefined) {
            this.charts.monthly.data[i][z] =
                +this.charts.monthly.data[i][z] + lead.dealValue;
          }
        }
      }
      if (
          moment(lead.updatedAt) >= moment().startOf("month")           
      ) {
        this.dealAmount.monthly =
            this.dealAmount.monthly + (lead.dealValue || 0);
      }
    });
    this.charts.target.data = [
      ["Deal Value", this.dealAmount.monthly],
      ["Target Value", this.dealAmount.target || 100],
    ];
    const chart = new D3Funnel("#funnel");
    this.charts.funnel.data = this.charts.funnel.data.map((x: any) => {
      x.value = (x.value / 100) * x.probabilityValue;
      this.dealAmount.pipeline = this.dealAmount.pipeline + x.value;
      x.value = x.numberofLeads;
      this.totalLeads = this.totalLeads + (x.numberofLeads || 0);
      return x;
    });
    this.charts.funnel.title =
    this.charts.funnel.title.substr(0, 14) +
        " (" + ( this.totalLeads ) + ")";

    chart.draw(this.charts.funnel.data, this.charts.funnel.options);

    this.activeUsersBrowserSessionsList.result.forEach((obj: any) => {
      this.charts.AUBrowserMetrics.data.push([obj.groupedByData, obj.count])
    })

    this.activeUsersByCountryList.result.forEach((obj: any) => {
      this.charts.AUByCountryMetrics.data.push([obj.groupedByData, obj.count])
    })

    this.activeUsersBrowserSessionsList.result.sort((a: any, b: any) => b.count - a.count);

    const topBrowsersData: any[] = [];
    let otherBrowsersCount = 0;

    this.activeUsersBrowserSessionsList.result.forEach((obj: any, index: number) => {
      if (index < 3) {
        topBrowsersData.push([obj.groupedByData, obj.count]);
      } else {
        otherBrowsersCount += obj.count;
      }
    });

    if (otherBrowsersCount > 0) {
      topBrowsersData.push(['Others', otherBrowsersCount]);
    }
    this.charts.AUBrowserMetrics.data = topBrowsersData;

    setTimeout(()=>{
      this.count$.next({ ...this.count });
    },100)
    
  }

  public onReady() {
    this.isChartLoaded = true;
  }

  public growthChart() {
    this.showGrowth = true;
    this.showSales = false;
    this.showCustomerExp = false;
    this.funnelCreateClicked = false;
  }

  public salesChart() {
    this.showSales = true;
    this.showGrowth = false;
    this.showCustomerExp = false;
    this.funnelCreateClicked = false;
  }

  public customerExperienceChart() {
    this.showCustomerExp = true;
    this.showSales = false;
    this.showGrowth = false;
    this.funnelCreateClicked = false;
  }

  public onTemplateSelect(template: any) {
    this.templateId = template.objectId;
    this.templateType = template.type;
    this.templateDescription = template.description;
  }

  public async createStages() {
    try {
      this.funnelCreateClicked = true;
      const funnelParams = {
        templateId: this.templateId,
        type: this.templateType,
        spaceName: this.newSpaceName || "Leads",
      };
      const response: any = await this.dataService.postToServer(
          "functions/createFunnelTemplate",
          funnelParams
      );
      if (response) {
        this.createdSpaceId = response.result;
        this.funnelModal!.hide();
        if (!this.isProfileCompleted && this.spaceListLength == 0) {
          this.companyProfileModal!.show();
        } else {
          this.fetchDashboardData();
          this.hasFunnelChartData = true;
          this.router.navigateByUrl("/leads/" + response.result, {
            replaceUrl: true,
          });
        }
      } else {
        this.notifier.notify("error", "Something went wrong, please try again");
      }
    } catch (e) {
      this.notifier.notify("error", "Something went wrong, please try again");
      this.funnelCreateClicked = false;
    }
  }

  public async fetchFunnelTemplates() {
    if (this.authService.isAdmin()) {
      // Fetch only Active Templates
      let params = {
        where: {
          isActive: true,
        },
        order: "order"
      };
      const resp = await this.dataService.getFromServer(
          "classes/Templates",
          params
      );
      this.funnelTemplates = resp.results || [];
      this.funnelTemplates.forEach((template: any) => {
        if (template.type == "growth") {
          this.templateId = template.objectId;
          this.templateType = template.type;
          this.templateDescription = template.description;
        }
      });
    }
  }

  public getLastActivity(lastActivityDate: any) {
    if (lastActivityDate) {
      return moment(new Date(lastActivityDate.iso)).fromNow();
    }
    return "";
  }

  public async openCompanyProfile() {
    this.companyProfileModal!.show();
  }

  public async updateCompanyProfile() {
    const companyName = this.companyProfileForm.get("companyName")!.value;
    // const companyBio = this.companyProfileForm.get("companyBio")!.value;
    const timeZone = this.companyProfileForm.get("timeZone")!.value;
    const currency = this.companyProfileForm.get("currency")!.value;
    const salesTarget = this.companyProfileForm.get("salesTarget")!.value;
    const northStarMetric = this.companyProfileForm.get("northMetric")!.value;
    const businessModel = this.companyProfileForm.get("businessModel")!.value;
    // const profileFormUrl = this.companyProfileForm.get("profileFormUrl")!.value;
    if (!companyName) {
      return this.notifier.notify("error", "Please Enter Name");
    }
    // if (!companyBio) {
    //   return this.notifier.notify("error", "Please Enter Bio");
    // }
    if (!timeZone) {
      return this.notifier.notify("error", "Please Select Time Zone");
    }
    if (!currency) {
      return this.notifier.notify("error", "Please Select Currency");
    }
    if (!salesTarget) {
      return this.notifier.notify("error", "Please Enter sales target");
    }
    if (!northStarMetric) {
      return this.notifier.notify("error", "Please Select North Star Metric");
    }
    if (!businessModel) {
      return this.notifier.notify("error", "Please Select Business Model");
    }
    // if (!profileFormUrl) {
    //   return this.notifier.notify("error", "Please Enter Website Address");
    // }
    try {
      const entityParams = {
        entityId: this.authService.getUser().entityId.objectId,
        companyName: this.companyProfileForm.get("companyName")!.value,
       // companyBio: this.companyProfileForm.get("companyBio")!.value,
        timeZone: this.companyProfileForm.get("timeZone")!.value,
        currency: this.companyProfileForm.get("currency")!.value,
        salesTarget: +this.companyProfileForm.get("salesTarget")!.value,
        isProfileCompleted: true,
        northStarMetric: this.companyProfileForm.get("northMetric")!.value,
        businessModel: this.companyProfileForm.get("businessModel")!.value,
        // websiteUrl: this.companyProfileForm.get("profileFormUrl")!.value,
        // phoneNumber: this.companyProfileForm.get("entityPhoneNumber")!.value,
      };
      await this.dataService.postToServer(
          "functions/updateEntityData",
          entityParams
      );
      this.companyProfileModal!.hide();
      this.updateEventsApiKey();
      this.firstWidgetsModal!.show();
    } catch (e) {
      this.companyProfileModal!.hide();
      this.notifier.notify("error", "Something went wrong, please try again");
    }
  }

  public async saveContact() {
    const name = this.contactForm.get("name")!.value;
    const email = this.contactForm.get("email")!.value;
    const phoneNumber = this.contactForm.get("phone")!.value.toString();
    const city = this.addressComponents.city;
    const gender = this.contactForm.get("gender")!.value;
    const address = this.addressComponents.address;
    const state = this.addressComponents.state;
    const country = this.addressComponents.country;
    const pincode = +this.addressComponents.pincode || undefined;

    if (!name) {
      this.notifier.notify("error", "Name Cannot be empty");
      return;
    }
    if (!email) {
      this.notifier.notify("error", "Email Cannot be empty");
      return;
    }
    if (!phoneNumber) {
      this.notifier.notify("error", "Phone Number Cannot be empty");
      return;
    }
    if (!gender) {
      this.notifier.notify("error", "Gender Cannot be empty");
      return;
    }

    let contactsParams: ContactsParams;
    if (this.imgURL && this.imgURL.name && this.imgURL.url) {
      contactsParams = {
        name,
        email,
        phoneNumber,
        gender,
        address,
        city,
        state,
        pincode,
        country,
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
        profilePhoto: {
          name: this.imgURL.name,
          url: this.imgURL.url,
          __type: "File",
        },
      };
    } else {
      contactsParams = {
        name,
        email,
        phoneNumber,
        gender,
        address,
        city,
        state,
        pincode,
        country,
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
      };
    }
    if (await this.checkIfContactExists(phoneNumber, email)) {
      this.notifier.notify("error", "Contact Already Exists!!");
      return;
    } else {
      if (this.currentContact) {
        await this.dataService.updateToServer(
            "classes/Contacts/" + this.currentContact,
            contactsParams
        );
        this.currentContact = "";
        this.notifier.notify("success", "Contact Edited Successfully!!");
      } else {
        contactsParams.agent = {
          __type: "Pointer",
          className: "_User",
          objectId: this.currentUser!.objectId,
        };
        contactsParams.assignTo = {
          __type: "Pointer",
          className: "_User",
          objectId: this.currentUser!.objectId,
        };
        contactsParams.isActive = true;

        await this.dataService.postToServer("classes/Contacts", contactsParams);
        this.notifier.notify("success", "Contact Created Successfully!!");
      }

      this.contactForm.reset();
      this.contactModal.hide();
    }
  }

  public async checkIfContactExists(phoneNumber: string, email: string) {
    const contactsQuery = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
        phoneNumber,
        email,
      },
    };
    const resp = await this.dataService.getFromServer(
        "classes/Contacts",
        contactsQuery
    );
    return resp.results.length !== 0;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      "has-error": this.isFieldValid(field, currentForm),
      "has-feedback": this.isFieldValid(field, currentForm),
    };
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public async onSelectFile(event: Event) {
    this.profilePhotoLoader = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if (files[0].size / (1024 * 1024) > 1) {
        this.notifier.notify(
            "error",
            "Please select a file size less than 1 MB."
        );
      } else {
        this.imgURL = await this.dataService.uploadFile(files[0]);
      }
    }
    this.profilePhotoLoader = false;
  }

  public addressChange(address: any) {
    // setting address from API to local variable
    this.formattedaddress = address.formatted_address;
    this.addressComponents.address = address.formatted_address;
    for (const component of address.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
      switch (componentType) {
        case "postal_code": {
          this.addressComponents.pincode = component.long_name;
          break;
        }
        case "locality":
          this.addressComponents.city = component.long_name;
          break;
        case "administrative_area_level_1": {
          this.addressComponents.state = component.long_name;
          break;
        }
        case "country":
          this.addressComponents.country = component.long_name;
          break;
      }
    }
  }

  public async getLeadsForTasks() {
    // const leadsQuery = {
    //   where: {
    //     entity: {
    //       __type: "Pointer",
    //       className: "Entity",
    //       objectId: this.authService.getUser().entityId.objectId,
    //     },
    //     isArchived: { $ne: true },
    //   },
    // };
    // const resp = await this.dataService.getFromServer(
    //     "classes/Leads",
    //     leadsQuery
    // );
    // this.leadsListData = resp.results;
  }

  public async getTaskList() {
    let taskParams: any = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
        isArchive: { $ne: true },
      },
      include: "stage,lead,lead.assignTo,lead.contact",
      order: "-createdAt",
      limit:10000,
    };

    let resp = await this.dataService.getFromServer(
        "classes/Tasks",
        taskParams
    );
    this.tasksList = resp.results || [];
    this.calendarTasksList = await getTasksData(resp.results, 'dashboardCalender');
  }

  public async getActivity() {
    const lastThreeDays = moment().subtract(3, 'days').startOf('day').toISOString();
    //const thisWeek = moment().subtract(7, 'days').startOf('day').toISOString();
    const activityParams = {
        where: {
            entity: {
                __type: "Pointer",
                className: "Entity",
                objectId: this.authService.getUser().entityId.objectId,
            },
            updatedAt: {
                $gte: {
                    __type: "Date",
                    iso: lastThreeDays,
                },
            },
        },
        order: "-updatedAt",
    };

    try {
        const activity = await this.dataService.getFromServer(
            'classes/Activity',
            activityParams,
        );
        this.activityData = activity.results || [];
    } catch (error) {
        console.error("Error fetching activity data:", error);
    }
  }

  getRelativeTime(date: string | Date): string {
    const duration = moment.duration(moment().diff(moment(date)));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (years > 0) return `${years} y ago`;
    if (months > 0) return `${months} mo ago`;
    if (days > 0) return `${days} d ago`;
    if (hours > 0) return `${hours} h ago`;
    if (minutes > 0) return `${minutes} min ago`;
    return 'just now';
  }

  // /**/public async fetchFollowUpData() {
  //   const params = {
  //     include: "entity,user,questions",
  //     where: {
  //       entity: {
  //         __type: "Pointer",
  //         className: "Entity",
  //         objectId: this.authService.getUser().entityId.objectId,
  //       },
  //     },
  //   };
  //   const paramsResponse = await this.dataService.getFromServer(
  //       "classes/FollowUps",
  //       params
  //   );
  //   // tslint:disable-next-line:no-any
  //   let followUpResp: any = {};
  //   if (paramsResponse.results) {
  //     if (paramsResponse.results.length) {
  //       followUpResp = paramsResponse.results[0];
  //       this.followUpCount = paramsResponse.results.length;
  //       this.followUpId = followUpResp.objectId;
  //       this.standUpData = {};
  //     }
  //   }
  //   followUpResp.questions.forEach((q: any, i: number) => {
  //     this.standUpData[`question${i + 1}`] = {
  //       question: q.name,
  //       answer: "",
  //       questionId: q.objectId,
  //       followupRespId: "",
  //     };
  //   });
  //   if (Object.entries(this.standUpData).length > 0) {
  //     this.currentQuestionData = {
  //       index: 1,
  //       questionId: this.standUpData[`question${1}`].questionId,
  //       answer: this.standUpData[`question${1}`].answer,
  //     };
  //   }
  // }

  public nextQuestion = () => {
    let key = "question" + this.currentQuestionData.index;
    this.standUpData[key].answer = this.currentQuestionData.answer;
    this.currentQuestionData.index += 1;
    key = "question" + this.currentQuestionData.index;
    this.currentQuestionData.answer = this.standUpData[key].answer;
  };

  public previousQuestion = () => {
    this.currentQuestionData.index -= 1;
    const key = "question" + this.currentQuestionData.index;
    this.currentQuestionData.answer = this.standUpData[key].answer;
  };

  // public saveFollowUpResponses = async () => {
  //   this.isSubmitted = true;
  //   const key = "question" + this.currentQuestionData.index;
  //   this.standUpData[key].answer = this.currentQuestionData.answer;
  //   this.currentQuestionData.answer = this.standUpData[key].answer;
  //   const submitParams: any = {
  //     user: {
  //       __type: "Pointer",
  //       className: "_User",
  //       objectId: this.authService.getUser().objectId,
  //     },
  //     entity: {
  //       __type: "Pointer",
  //       className: "Entity",
  //       objectId: this.authService.getUser().entityId.objectId,
  //     },
  //     followup: {
  //       __type: "Pointer",
  //       className: "FollowUps",
  //       objectId: this.followUpId,
  //     },
  //   };
  //   // @ts-ignore
  //   Object.keys(this.standUpData).forEach(async (key) => {
  //     const obj = {
  //       answer: this.standUpData[key].answer,
  //       question: {
  //         __type: "Pointer",
  //         className: "Questions",
  //         objectId: this.standUpData[key].questionId,
  //       },
  //       ...submitParams,
  //     };
  //     await this.dataService.postToServer("classes/FollowUpResponse", obj);
  //   });
  //   // await this.getTodaysFollowUpsResponse();
  //   this.isSubmitted = false;
  //   this.closefollowUpModal();
  //   this.notifier.notify("success", "FollowUp Saved Successfully !!!");
  // };

  public showStandUpButton = (button: string) => {
    if (button === "previous") {
      return this.currentQuestionData.index > 1;
    }
    if (button === "next") {
      return (
          this.currentQuestionData.index < Object.entries(this.standUpData).length
      );
    }
    if (button === "submit") {
      return (
          this.currentQuestionData.index ===
          Object.entries(this.standUpData).length
      );
    }
    return false;
  };

  public async showFollowUpModal() {
    // await this.fetchFollowUpData();
    if (this.followUpCount === 1) {
      this.standUpModal!.hide();
    } else {
      this.notifier.notify("error", "No Records Found !!!");
    }
  }

  public async showDAUMetrics() {
    this.router.navigateByUrl("/dau-metrics");
  }

  public closefollowUpModal() {
    this.standUpModal!.hide();
  }

  // public async dailyUsersCount() {
  //   let params = {
  //     entityId: this.authService.getUser().entityId.objectId,
  //   };
  //   const paramsResponse = await this.dataService.postToServer(
  //       "functions/getEventContacts",
  //       params
  //   );
  //   let responseObj = JSON.parse(paramsResponse.result);
  //   this.count.dau = responseObj.dau ? responseObj.dau : 0;
  //   this.sharedDataService.changeDau(this.count.dau);
  //   this.count.prevDau = responseObj.prevDau ? responseObj.prevDau : 0;
  //   this.count.currentChurn = responseObj.currentChurn ? parseInt(responseObj.currentChurn) : 0;
  //   this.sharedDataService.changeChurnRate(this.count.currentChurn);
  //   this.count.previousChurn = responseObj.previousChurn ? parseInt(responseObj.previousChurn) : 0;
  //
  //   // AVG DAU
  //   if(this.count.dau !== 0 && this.count.prevDau !== 0) {
  //     this.count.dauArrow =
  //         this.count.dau > this.count.prevDau ? 'up' :
  //             this.count.dau < this.count.prevDau ? 'down' :
  //                 'no-change';
  //
  //     this.count.dauPercentage = parseInt(Math.abs(((this.count.dau - this.count.prevDau) / this.count.prevDau) * 100).toFixed(0));
  //   }
  //
  //   if(this.count.currentChurn !== 0 && this.count.previousChurn !==0 ) {
  //     //churn rate trend arrow
  //     let arrow;
  //     if (this.count.currentChurn > this.count.previousChurn) {
  //       arrow = "up";
  //     } else if (this.count.currentChurn < this.count.previousChurn) {
  //       arrow = "down";
  //     } else {
  //       arrow = "no-change";
  //     }
  //     this.count.arrow = arrow;
  //     this.count.churnPercentage = parseInt(Math.abs(((this.count.currentChurn - this.count.previousChurn) / this.count.previousChurn) * 100).toFixed(0));
  //   }
  //
  //   //previous month churn rate value
  //   let churnRateChange = `${this.count.previousChurn}% last month`;
  //   this.count.churnRateChange = churnRateChange;
  //
  //   //here retentionRate = 1 – churn%
  //   //current month retention rate
  //   let currentMonthRetentionRate =  1 - (this.count.currentChurn / 100);
  //   this.currentMonthRetentionRate = (currentMonthRetentionRate * 100).toFixed(0);
  //   this.sharedDataService.changeRetentionRate(this.currentMonthRetentionRate);
  //
  //   //previous month retention rate
  //   let PreviousMonthRetentionRate =  1 - (this.count.previousChurn / 100);
  //   this.PreviousMonthRetentionRate = (PreviousMonthRetentionRate * 100).toFixed(0);
  //
  //   //previous month retention rate value
  //   let retentionRateChange = `${this.PreviousMonthRetentionRate}% last month`;
  //   this.count.retentionRateChange = retentionRateChange;
  //
  //
  //   if(currentMonthRetentionRate !== 0 && PreviousMonthRetentionRate !== 0) {
  //     //retention rate trend arrow
  //     let arrowR;
  //     if (currentMonthRetentionRate > PreviousMonthRetentionRate) {
  //       arrowR = "up";
  //     } else if (currentMonthRetentionRate < PreviousMonthRetentionRate) {
  //       arrowR = "down";
  //     } else {
  //       arrowR = "no-change";
  //     }
  //     this.count.arrowR = arrowR;
  //     this.count.retentionRatePercentage = parseInt(Math.abs(((currentMonthRetentionRate - PreviousMonthRetentionRate) / PreviousMonthRetentionRate) * 100).toFixed(0));
  //   }
  //
  //   setTimeout(()=>{
  //     this.count$.next({ ...this.count });
  //   },100)
  // }

  public openEventLogger(link: any) {
    window.open(link, "_blank");
  }

  public connectToRazorpay(){
    const serverUrl = environment.apiUrl;
    const updatedServerUrl = serverUrl.replace('/parse/', '/');
    const authUrl = `${updatedServerUrl}auth/razorpay?apikey=${this.eventsApiKey}`;
    const authWindow = window.open(authUrl, 'authWindow', 'width=1000,height=1000');

    if (!authWindow) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }

    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const resultElement = document.getElementById('result');
      const authButton = document.getElementById('authButton') as HTMLButtonElement;

      if (resultElement) {
        if (event.data.type === 'oauth-success') {
          resultElement.innerText = 'Razorpay Connection Successful!';
          if (authButton) {
            authButton.disabled = true;
          }
          localStorage.setItem('razorpayAuthStatus', 'authenticated');
        } else if (event.data.type === 'oauth-failure') {
          resultElement.innerText = 'Razorpay Connection Failed!';
        }
      }

      if (authWindow) {
        authWindow.close();
      }
    });
  }


  public connectToFb() {
    const serverUrl = environment.apiUrl;
    const updatedServerUrl = serverUrl.replace('/parse/', '/');
    const authUrl = `${updatedServerUrl}auth/facebook?apikey=${this.eventsApiKey}`;
    const authWindow = window.open(authUrl, 'authWindow', 'width=1000,height=1000');

    if (!authWindow) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }

    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const resultElement = document.getElementById('result');
      const authButton = document.getElementById('authButton') as HTMLButtonElement;

      if (resultElement) {
        if (event.data.type === 'oauth-success') {
          resultElement.innerText = 'Facebook Connection Successful!';
          if (authButton) {
            authButton.disabled = true;
          }
          localStorage.setItem('fbAuthStatus', 'authenticated');
        } else if (event.data.type === 'oauth-failure') {
          resultElement.innerText = 'Facebook Connection Failed!';
        }
      }

      if (authWindow) {
        authWindow.close();
      }
    });
  }

  public fbButtonLabel(): string {
    return this.fbPages.length > 0 ? 'UPDATE CONNECTION' : 'CONNECT ACCOUNT';
  }

  public nextButtonLabel(): string {
    return this.fbPages.length > 0 ? 'NEXT' : 'MAYBE LATER';
  }

  public async getFBSubscribedPages() {
    const resp = await this.dataService.postToServer(
      'functions/get-fb-subscribed-pages', {});
    this.fbPages = resp.result;
  }

  public async checkIsFbIntegrated(){
    if(this.fbPages.length == 0){
      this.fbNotIntegrated = true;
    }
  }

  public async getTotalEmailCount(){
    try{
      this.entityResponse = await this.dataService.getFromServer(
        "classes/Entity/" + this.authService.getUser().entityId.objectId
      );
      this.messagesCountVal = this.entityResponse.messagesCount || 0;
    }
    catch(error){
      console.error(error);
    }
  }

  //Fetch Tasks Stages List 
public async getTasksStagesList() {
  const stageQuery = {
    where: {
      templateType: "task",
      entity: {
        __type: "Pointer",
        className: "Entity",
        objectId: this.authService.getUser().entityId.objectId,
      },
    },
  };
  const resp = await this.dataService.getFromServer(
    "classes/Stages",
    stageQuery
  );
  this.taskStagesList = resp.results;
}

public async fetchLeadsListData() {
  const getCurrentUser = this.authService.getUser();
  let params: any;
  if (this.authService.isSuperAdmin()) {
      params = {
          include: "status,stage,contact",
          where: {
              isArchived: { $ne: true },
          },
      };
  } else if (this.authService.isAdmin()) {
      params = {
          // include: "stage,contact",
          include: "status,stage,contact",
          order: "-dealValue",
          where: {
              entity: {
                  __type: "Pointer",
                  className: "Entity",
                  objectId: this.authService.getUser().entityId.objectId,
              },
              // status: {
              //     $ne: {
              //         __type: "Pointer",
              //         className: "Status",
              //         objectId: "tt4nl8dJg6",
              //     },
              // },
              isArchived: { $ne: true },
          },
      };
  } else if (this.authService.isAgent()) {
      params = {
          include: "status,stage,contact",
          where: {
              entity: {
                  __type: "Pointer",
                  className: "Entity",
                  objectId: this.authService.getUser().entityId.objectId,
              },
              // status: {
              //     $ne: {
              //         __type: "Pointer",
              //         className: "Status",
              //         objectId: "tt4nl8dJg6",
              //     },
              // },
              isArchived: { $ne: true },
          },
      };
      if(!!getCurrentUser.isPrivate) {
          params = {
              where: {
                  ...params.where,
                  $or: [
                      {
                          agent: {
                              __type: 'Pointer',
                              className: '_User',
                              objectId: this.authService.getUser().objectId,
                          },
                      },
                      {
                          assignTo: {
                              __type: 'Pointer',
                              className: '_User',
                              objectId: this.authService.getUser().objectId,
                          },
                      },
                  ],
              },
          }
      }
  } else if (this.authService.isManager()) {
      params = {
          include: "status,stage,contact",
          where: {
              $or: [
                  {
                      agent: {
                          $inQuery: {
                              where: {
                                  manager: {
                                      __type: "Pointer",
                                      className: "_User",
                                      objectId: this.authService.getUser().objectId,
                                  },
                              },
                              className: "_User",
                          },
                      },
                  },
                  {
                      assignTo: {
                          $inQuery: {
                              where: {
                                  manager: {
                                      __type: "Pointer",
                                      className: "_User",
                                      objectId: this.authService.getUser().objectId,
                                  },
                              },
                              className: "_User",
                          },
                      },
                  },
                  {
                      agent: {
                          __type: "Pointer",
                          className: "_User",
                          objectId: this.authService.getUser().objectId,
                      },
                  },
                  {
                      assignTo: {
                          __type: "Pointer",
                          className: "_User",
                          objectId: this.authService.getUser().objectId,
                      },
                  },
                ],
              // status: {
              //     $ne: {
              //         __type: "Pointer",
              //         className: "Status",
              //         objectId: "tt4nl8dJg6",
              //     },
              // },
              isArchived: { $ne: true },
          },
      };
  }

  params.limit = 10000;
  const leadsResp = await this.dataService.getFromServer(
    "/classes/Leads",
    params
);
return leadsResp.results;      
};

public async getSpaceList(){
  const spaceParams = {
              where: {
                  entity: {
                      __type: "Pointer",
                      className: "Entity",
                      objectId: this.authService.getUser().entityId.objectId,
                  },
                  isArchived: { $ne: true },
                },
              };
          const spaceResp = await this.dataService.getFromServer(
                 "classes/Spaces",
                 spaceParams
            );
          const spaceList = spaceResp.results && spaceResp.results.length > 0 ? [spaceResp.results[0]] : [];
          return spaceList;
  }
                          


}
