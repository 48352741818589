import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from '../../types/user';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { Contacts, ContactsParams } from '../../types/contacts';
import * as moment from 'moment';
import { AuthService } from '../../services/auth';
import { DataService } from '../../services/data.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotifierService } from 'angular-notifier';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import * as xlsx from 'xlsx';
import { DataTableDirective } from 'angular-datatables';
import { validationPattern } from '../../constants/regexp';
import Swal from 'sweetalert2';
import {Subject} from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LeadParams } from '../../types/leads';
import { LeadsService } from '../../services/leads.service';
import { Products } from 'src/app/types/products';
import { track } from '@inleads/event-logger';
import { ListParams } from "src/app/types/kanban";
import { Stages } from "src/app/types/stages";



@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('contactModal') public contactModal: ModalDirective;
  @ViewChild(DataTableDirective)
  public dtElement: DataTableDirective;
  public dtTrigger: Subject<any> = new Subject();
  public currentUser: User | undefined;
  public contactForm: FormGroup;
  public dtOptions: any = {};
  public contactsPageData: Contacts[] = [];
  public isLoading: boolean;
  public selected: boolean;
  public minDate: any;
  public currentContact: string;
  public profilePhotoLoader: boolean;
  public imgURL: any;
  public formattedaddress:string;
  public showDeactivatedContacts = false;
  public addressComponents = {
    address : '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  }
  public newLeadForm : FormGroup;
  public newProductForm: FormGroup;
  public disableFields: boolean;
  public productID: any;
  public productList: any[] = [];
  public spacesList: any[];
  public stagesList: any[];
  public selectedSpace: any;
  public contactObjectId: any;
  public isSubmitButtonDisabled = false;
  @ViewChild('newlead') public newleadModal?: ModalDirective;
  @ViewChild('csvModal') public csvModal: ModalDirective;
  @ViewChild('newProduct') public newProductModal?: ModalDirective;
  @ViewChild("editStage") public editStageModal?: ModalDirective;
  public isVisible: boolean = false;
  public displayKanbanContent: boolean = false;
  public newtorkTemplateId: any;
  public networkStagesList: Stages[];
  public kanbanList : ListParams[];
  public kanbanDeactivateModal: boolean = false;
  public deacivateContactIdInKanban: any;
  public kanbanActivateModal: boolean = false;
  public activateContactIdInKanban: any;
  public editStageForm: FormGroup;
  public selectedStageData: any[];
  public kanbanEditStage: boolean = false;
  public selectedEditStageId: any;
  dtInstance: DataTables.Api;
  //public bulkActionChecked: boolean = false;
  public bulkArchiveModal: boolean = false;
  public bulkUnarchiveModal: boolean = false;
  
  constructor(
    private dataService: DataService,
    public authService: AuthService,
    private notifier: NotifierService,
    public userService: UserService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder,
    private leadsService: LeadsService,
  ) {}

  public  ngOnInit() {
    this.displayKanbanContent = false;
    this.ngxService.start();
    this.intializeLeadForm();
    this.formattedaddress='';
    this.loadContactsAndStageData();
    //await this.loadContactsRequests();
    this.currentUser = this.userService.getUser();
    track("CONTACTS");
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.pattern(validationPattern.email),
        ]),
      phone: new FormControl('', [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(validationPattern.phone),
      ]),
      gender: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      /* pincode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),*/
    });
    this.getSpaces();
    this.getProducts();
    this.initializeEditStageForm();
    this.ngxService.stop();
  }

  public async loadContactsAndStageData(){
    await this.loadContactsRequests();
    this.getNetworkStagesList()
  }

  public addressChange(address: any) {
    // setting address from API to local variable
    this.formattedaddress=address.formatted_address;
    this.addressComponents.address = address.formatted_address;
    for (const component of address.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
      switch (componentType) {
        case 'postal_code': {
          this.addressComponents.pincode = component.long_name;
          break;
        }
        case 'locality':
          this.addressComponents.city = component.long_name;
          break;
        case 'administrative_area_level_1': {
          this.addressComponents.state = component.long_name;
          break;
        }
        case 'country':
          this.addressComponents.country = component.long_name;
          break;
      }
    }
  }

  public ngAfterViewInit() {
    this.dtTrigger.next();

    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   this.dtInstance = dtInstance;
  
    //   // Listen to row select and deselect events
    //   this.dtInstance.on('select deselect', () => {
    //     const allRows = this.dtInstance.rows().nodes().toArray();
    //     const selectedRows = this.dtInstance.rows({ selected: true }).nodes().toArray();
  
    //     // Update the "Select All" checkbox
    //     const selectAllCheckbox = document.getElementById('select-all') as HTMLInputElement;
    //     selectAllCheckbox.checked = allRows.length === selectedRows.length;
    //     selectAllCheckbox.indeterminate =
    //       selectedRows.length > 0 && selectedRows.length < allRows.length;
    //   });
    // });

  }

  public ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  public onExportClicked() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.button(0).trigger();
    });
  }

  public async loadContactsRequests(skipTableRender = false) {
    if (!skipTableRender) {
    this.isLoading = true;
    }
    const getCurrentUser = this.authService.getUser();
    let customerParams;
    if(this.authService.isSuperAdmin()){
      customerParams = {
        include: 'agent',
        limit: 10000,
        order: '-createdAt',
        where: {
          email:{ $ne :'anonymous@inleads.ai'},
          isActive: !this.showDeactivatedContacts,
        },
      };
    } else if(this.authService.isAdmin()){
      customerParams = {
        include: 'agent',
        limit: 10000,
        order: '-updatedAt',
        where: {
          // isActive: !this.showDeactivatedContacts,
          isActive: { $ne : this.showDeactivatedContacts },
          email:{ $ne :'anonymous@inleads.ai'},
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    } else if(this.authService.isAgent()){
      customerParams = {
        where: {
          isActive: !this.showDeactivatedContacts,
          email:{ $ne :'anonymous@inleads.ai'},
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
        include: 'agent',
        limit: 10000,
        order: '-createdAt',
      };
      if(!!getCurrentUser.isPrivate) {
        customerParams = {
          where: {
            ...customerParams.where,
            $or: [
              {
                agent: {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.authService.getUser().objectId,
                },
              },
              {
                assignTo: {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.authService.getUser().objectId,
                },
              },
            ],
          },
        }
      }
    } else if (this.authService.isManager()) {
      const parameters = {
        where: {
          isActive: !this.showDeactivatedContacts,
          manager: {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authService.getUser().objectId,
          },
        },
      };
      const userResponse = await this.dataService.getFromServer(
        'classes/_User',
        parameters,
      );
      const agentsList: {
        __type: string;
        className: string;
        objectId: string;
      }[] = [];
      userResponse.results.forEach((user: User) => {
        agentsList.push({
          __type: 'Pointer',
          className: '_User',
          objectId: user.objectId,
        });
      });
      agentsList.push({
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      });
      customerParams = {
        include: 'agent',
        limit: 10000,
        order: '-createdAt',
        where: {
          isActive: !this.showDeactivatedContacts,
          $or: [
            { agent: { $in: agentsList } },
            { assignTo: { $in: agentsList } },
          ],
        },
      };
    }
    const contacts = await this.dataService.getFromServer(
      'classes/Contacts',
      customerParams,
    );

    this.contactsPageData = contacts.results || [];
    console.log(this.contactsPageData);
    if(this.authService.isAdmin() && this.showDeactivatedContacts){
      this.contactsPageData = this.contactsPageData.filter(x => x.isActive === false);
    }  
    this.isLoading=false;

    const groupPromises = this.contactsPageData.map(async (contact: any) => {
      const groups = await this.contactsInGroup(contact);
      contact.groupName = groups.map((group: { groupName: any; }) => group.groupName).join(', ') || 'NA';
    });

    await Promise.all(groupPromises);

    this.dtOptions = {
      order: [
        // [3, 'desc'],
        // [4, 'desc'],
       [6, 'desc'],
      ],
      dom: '<"appRequestsFilter"lf>tipr',
      buttons: [
        {
          extend: 'csv',
          title: 'Export InLeads-Contacts',
        },
      ],
      select: {
        style: 'multi',  // Enables multi-row selection
        selector: 'td:first-child'  // Allows selection via the first column (checkbox)
      },
      columnDefs: [
        {
          orderable: false,
          className: 'select-checkbox',
          targets: 0,
          width: '25px',
          responsivePriority: 1,
          visible: true,
        },
        // {
        //   targets: [1],
        //   visible: true,
        // },
        // {
        //   orderable: true,
        //   targets: [2],
        //   visible: true,
        // },
        {
          responsivePriority: 1,
          targets: 1,
          visible: true,
        },
        {
         responsivePriority: 2,
          targets: 2,
          visible: true,
        },
        {
         responsivePriority: 3,
          targets: 3,
          visible: true,
        },
        {
         responsivePriority: 4,
          targets: 4,
          visible: true,
        },
          {
          targets: 5,
         visible: true,
         responsivePriority: 5,
        },
        {
         orderable: true,
          targets: 6,
         responsivePriority: 6,
          visible: true,
        },
        {
          orderable: true,
           targets: 7,
          responsivePriority: 7,
           visible: true,
         },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: '_MENU_',
      },
      responsive: true,
      data: this.contactsPageData,
      info: false,
      columns: [
        { data: '' },
        // {
        //   data: (row: Contacts) => {
        //     return moment(row.createdAt).format('DD-MM-YYYY');
        //   },
        // },
        // {
        //   data: (row: Contacts) => {
        //     return moment(row.updatedAt).format('DD-MM-YYYY');
        //   },
        // },
        {
          data: (row: Contacts) => {
            let name = row.name;
            if (!name) {
              name = row.name || 'NA';
            }
            return name ? name : 'NA';
            },
        },
        // {
        //   data: (row: Contacts) => {
        //     return row.phoneNumber ? row.phoneNumber : 'NA';
        //   },
        // },
        {
          data: (row: Contacts) => {
            return row.email ? row.email : 'NA';
          },
        },
        {
          data: (row: Contacts) => {
            return row.city ? row.city : 'NA';
          },
        },
        {
          data: (row: Contacts) => {
            return row.groupName ? row.groupName : 'NA';
          },
        },
        {
          data: (row: Contacts) => {
            return moment(row.createdAt).format('YYYY-MM-DD');
          },
        },
        {
          data: (row: Contacts) => {
            return moment(row.updatedAt).format('YYYY-MM-DD');
          },
        },
        {
          data: (row:Contacts) => {
            const iconType = this.showDeactivatedContacts
              ? ' fa-check activateContact'
              : ' fa-trash inActivateContact';
            const tootlipTitle = this.showDeactivatedContacts
              ? ' UnArchive Contact'
              : ' Archive Contact';
            const phoneNumber = row.phoneNumber ? row.phoneNumber : 'NA';
            return (
              '<span class="fa fa-pencil cpointer" title="Edit"></span>'+
              '<span class="fa fa-plus cpointer" style="margin-left: 10px" title="Add lead"></span>' +
              '<a href="tel:' + phoneNumber + '"><span class="fa fa-phone cpointer" style="margin-left: 10px" title="Phone"></span></a>'+
              '<span class="fa ' +
              iconType +
              '" style="margin-left: 10px" title="'+tootlipTitle+'"></span>'
            );
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {

        row.removeEventListener('click', () => {});
        row.addEventListener('click', e => {
          // @ts-ignore
          if (e.target!.classList.contains('dtr-control') || e.target!.classList.contains('select-checkbox')
          ) {
            return;
          }
          let viewMode = true;
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.router.navigate(['/contact-details'], {
              state: {
                contactObjectId: (data as Contacts).objectId,
                isView: viewMode,
              },
            });
            return;
          }
           if ((e.target as HTMLElement).classList.contains('fa-plus')) {
            this.contactObjectId = (data as Contacts).objectId;
            this.openNewLeadModal();
          }
          if ((e.target as HTMLElement).classList.contains('inActivateContact')) {
            Swal.fire({
              title: '',
              text: 'Are you sure, You want to Archive the Contact and associated leads?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Archive',
              cancelButtonText: 'Cancel',
            }).then(result => {
              if (result.value) {
                this.deActivateContact((data as Contacts).objectId);
              }
            });
            return;
          }
          if ((e.target as HTMLElement).classList.contains('activateContact')) {
            Swal.fire({
              title: '',
              text: 'Please confirm, You want to Unarchive the Contact and associated leads?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Unarchive',
              cancelButtonText: 'Cancel',
            }).then(result => {
              if (result.value) {
                this.activateContact((data as Contacts).objectId);
              }
            });
            return;
          }
          if(!(e.target as HTMLElement).classList.contains('fa-plus')){
            this.router.navigate(['/contact-details'], {
              state: {
                contactObjectId: (data as Contacts).objectId,
                isView: viewMode,
              },
          });
          }
        });
        // @ts-ignore
        row.setAttribute('id', `id-${(data as Contacts).objectId}`);
        return row;
      },
    };
    if (!skipTableRender) {
      setTimeout(() => {
        if (this.dtElement && this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            setTimeout(() => {
      this.dtTrigger.next();
            }, 10);
          });
        } else {
          this.dtTrigger.next();
        }
      }, 10);
    }
    if(this.displayKanbanContent){
       this.getNetworkStagesList();
    }
  }

  public async deActivateContact(contactId: string) {
    this.ngxService.start();
    try {
      await this.dataService.updateToServer('classes/Contacts/' + contactId, {
        isActive: false,
      });
      this.notifier.notify('success','Contact and associated leads are also archived');
      this.contactsPageData = [...this.contactsPageData].filter(
        data => data.objectId !== contactId,
      );
      if(!this.displayKanbanContent){
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.row(`#id-${contactId}`).remove();
        dtInstance.draw();
      });
    }else{
       this.getNetworkStagesList();
    }
    } catch (e) {
      alert(e.message);
    }
    this.ngxService.stop();
  }

  public async activateContact(contactId: string) {
    this.ngxService.start();
    try {
      await this.dataService.updateToServer('classes/Contacts/' + contactId, {
        isActive: true,
      });
      this.notifier.notify('success','Contact and associated leads are also unarchived');
      this.contactsPageData = [...this.contactsPageData].filter(
        data => data.objectId !== contactId,
      );
      if(!this.displayKanbanContent){
       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.row(`#id-${contactId}`).remove();
        dtInstance.draw();
       });
    }
     else{
      this.getNetworkStagesList();
     }
    } catch (e) {
      alert(e.message);
    }
    this.ngxService.stop();
  }

  public async toggleUsers() {
    this.showDeactivatedContacts = !this.showDeactivatedContacts;
    this.ngxService.start();
    await this.loadContactsRequests(true);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      setTimeout(() => {
        this.dtTrigger.next();
      }, 100);
    });
    this.ngxService.stop();
  }

  public async loadActiveContacts() {
    this.showDeactivatedContacts = false;
    this.ngxService.start();
    await this.loadContactsRequests(false);
    this.ngxService.stop();
  }

  public async loadDeactivatedContacts() {
    this.showDeactivatedContacts = true;
    this.ngxService.start();
    await this.loadContactsRequests(true);
    if(this.displayKanbanContent){
       this.getNetworkStagesList();
    }
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      setTimeout(() => {
        this.dtTrigger.next();
      }, 100);
    });
    this.ngxService.stop();
  }

  public async editContact(contactId : string){
    this.isLoading = true;
    this.contactModal.show();
    this.currentContact = contactId;
    const contactParams = {
      include: 'agent,assignTo',
    };
    const contactResponse = await this.dataService.getFromServer(
      'classes/Contacts/' + contactId,
      contactParams,
      );
      this.contactForm.patchValue({
      name: contactResponse.name || '',
      email: contactResponse.email || '',
      phoneNumber: contactResponse.phoneNumber || 0,
      gender: contactResponse.gender || 'male',
      city: contactResponse.city || '',
      address: contactResponse.address || '',
      state: contactResponse.state || '',
      pincode: contactResponse.pincode || '',
      });
      this.isLoading = false;
  }
  public async checkIfContactExists(phoneNumber : string ,email: string){
    const contactsQuery = {
      where: {
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        phoneNumber,
        email,
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Contacts',
      contactsQuery,
    );
    if(resp.results.length === 0){
      // console.log('if block');
      return false;
    } else {
      // console.log('else block');
      return true;
    }
  }

  public async saveContact(){
    const name = this.contactForm.get('name')!.value;
    const email = this.contactForm.get('email')!.value;
    const phoneNumber = this.contactForm.get('phone')!.value.toString();
    const city = this.addressComponents.city;
    const gender = this.contactForm.get('gender')!.value;
    const address = this.addressComponents.address;
    const state =this.addressComponents.state;
    const country =this.addressComponents.country;
    const pincode = +this.addressComponents.pincode || undefined;
    const contactType = 'contact';
    if(!name){
      this.notifier.notify('error', 'Name Cannot be empty');
      return;
    }
    // if(!email){
    //   this.notifier.notify('error', 'Email Cannot be empty');
    //   return;
    // }
    // if(!phoneNumber){
    //   this.notifier.notify('error', 'Phone Number Cannot be empty');
    //   return;
    // }
    if(!gender){
      this.notifier.notify('error', 'Gender Cannot be empty');
      return;
    }
    // if(!address){
    //   this.notifier.notify('error', 'Address Cannot be empty');
    //   return;
    // }
    /*if(!city){
      this.notifier.notify('error', 'City Cannot be empty');
      return;
    }
    if(!state){
      this.notifier.notify('error', 'State Cannot be empty');
      return;
    }
    if(!pincode){
      this.notifier.notify('error', 'Zipcode Cannot be empty');
      return;
    }*/
    this.isSubmitButtonDisabled= true;
    let contactsParams: ContactsParams;
    if(this.imgURL && this.imgURL.name && this.imgURL.url){
       contactsParams = {
        name,
        email,
        phoneNumber,
        gender,
        address,
        city,
        state,
        pincode,
        country,
        contactType,
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        profilePhoto: {
          name: this.imgURL.name,
          url: this.imgURL.url,
          __type: 'File',
        },
      }
    }
    else{
      contactsParams = {
        name,
        email,
        phoneNumber,
        gender,
        address,
        city,
        state,
        pincode,
        country,
        contactType,
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
      };
    }
    if(await this.checkIfContactExists(phoneNumber, email)){
      this.notifier.notify('error', 'Contact Already Exists!!');
      return;
    } else {
    if(this.currentContact) {
      await this.dataService.updateToServer(
          'classes/Contacts/' + this.currentContact,
          contactsParams,
      );
        this.currentContact = '';
        this.notifier.notify('success', 'Contact Edited Successfully!!');
    } else {
        contactsParams.agent = {
          __type: 'Pointer',
          className: '_User',
          objectId: this.currentUser!.objectId,
        };
        contactsParams.assignTo = {
          __type: 'Pointer',
          className: '_User',
          objectId: this.currentUser!.objectId,
        };
        contactsParams.isActive = true;
        await this.dataService.postToServer('classes/Contacts', contactsParams);
        this.isSubmitButtonDisabled= false;
        this.notifier.notify('success', 'Contact Created Successfully!!');
    }
    this.isSubmitButtonDisabled= false;
    this.contactForm.reset();
    this.contactModal.hide();
    await this.loadContactsRequests();
    this.mapStagesToKanbanModel(this.networkStagesList);
    }
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public async importContacts(evt: any) {
    const file = evt.target.files[0];
    if (file.size / (1024 * 1024) > 5) {
      this.notifier.notify('error', 'Please select a file size less than 5MB.');
      return;
    }

    const prom = new Promise(resolve => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(evt.target.files[0]);
      fileReader.onload = () => {
        const fileData = new Uint8Array(fileReader.result as ArrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== fileData.length; ++i) {
          arr[i] = String.fromCharCode(fileData[i]);
        }
        const bstr = arr.join('');
        const workbook = xlsx.read(bstr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        // arraylist = xlsx.utils.sheet_to_json(worksheet, {raw: true});
        resolve(xlsx.utils.sheet_to_json(worksheet, { raw: true }));
      };
    });
    const arraylist:any = await prom;
const uniqueEmails = new Set(this.contactsPageData.map(obj => obj.email));

const filteredArraylist = arraylist.filter((obj:any) => !obj.email || !uniqueEmails.has(obj.email));
if(filteredArraylist.length > 0){
  const data = {
    contacts: filteredArraylist,
    user: this.currentUser!.objectId,
    entity: this.authService.getUser().entityId.objectId
  };
 await this.dataService.postToServer(
      'functions/importCsv',
      data,
    );
    const duplicates = arraylist.length - filteredArraylist.length;
    let msg = '';
    if (duplicates > 0) {
      msg = `${duplicates} duplicate contacts found ${filteredArraylist.length} imporded`;
    } else {
      msg = 'All contacts imported successfully';
    }
    this.loadContactsRequests().then();
    this.notifier.notify('success', msg);

  }
  this.csvModal!.hide();

  }

  public async onSelectFile(event: Event) {
    this.profilePhotoLoader = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if (files[0].size / (1024 * 1024) > 1) {
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'Please select a file size less than 1 MB.');
        this.profilePhotoLoader = false;
        return;
      } else {
        this.imgURL = await this.dataService.uploadFile(files[0]);
      }
    }
    this.profilePhotoLoader = false;
  }

  private intializeLeadForm(){
    this.newLeadForm = this.fb.group({
      objectId: ['', Validators.required],
      stage: [''],
      space: [''],
      product: [''],
      fullName: ['', Validators.required],
      email: [
        '',
        [Validators.pattern(validationPattern.email)],
      ],
      phone: ['',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(validationPattern.phone),
        ],
      ],
      dealValue: [''],
      gender: [''],
      city: [''],
      address: [''],
      state: [''],
      pincode: [''],
      description: [''],
      nextActivity: [new Date(), Validators.required],
      activityNotes: [''],
      assignTo: [''],
      creator: [''],
      leadAssignTo: [''],
      contactId: [''],
      closingDate: ['', Validators.required],
      noOfSeats: ['', Validators.required],
      estimatedContractLength: ['', Validators.required],
    });

    this.newProductForm = this.fb.group({
      product_name: ['', Validators.required],
      product_description: ['', Validators.required],
      price_type: ['', Validators.required],
      price_period: ['', Validators.required],
      new_price: [, Validators.required],
      new_sheets: [, Validators.required],
    });
  }
  public async openNewLeadModal(){
    this.newleadModal!.show();
  }

  public addProduct(event: Event) {
    event.preventDefault();
    this.newProductModal!.show();
  }

  public async createProduct(){
    const productName = this.newProductForm.get('product_name')!.value;
    const productDescription = this.newProductForm.get('product_description')!.value;
    const productPriceType = this.newProductForm.get('price_type')!.value;
    const productPricePeriod = this.newProductForm.get('price_period')!.value;
    const productPrice = this.newProductForm.get('new_price')!.value;
    const productNoOfSeats = this.newProductForm.get('new_sheets')!.value;
    if(!productName){
      this.notifier.notify('error', 'Name cannot be empty');
      return
    }
    if(!productDescription){
      this.notifier.notify('error', 'Description cannot be empty');
      return
    }
    if(!productPriceType){
      this.notifier.notify('error', 'Price Type cannot be empty');
      return
    }
    // if(!productPricePeriod){
    //   this.notifier.notify('error', 'Price Period cannot be empty');
    //   return
    // }
    if(!productPrice){
      this.notifier.notify('error', 'Price cannot be empty');
      return
    }
    // if(!productNoOfSeats){
    //   this.notifier.notify('error', 'No Of Seats cannot be empty');
    //   return
    // }
    const productParams: Products = {
      name: productName,
      description: productDescription,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      website: '',
      objectId: '',
      priceType: productPriceType,
      pricePeriod: productPricePeriod,
      price: productPrice,
      noOfSeats: productNoOfSeats,
    };
    const productResponse = await this.dataService.postToServer('classes/Products/', productParams);
    if(productResponse){
      this.notifier.notify('success', 'Product created Successfully!!!');
      this.productID = productResponse.objectId;
      this.newProductForm.reset();
      this.newProductModal!.hide();
    }
    // this.ngxLoader.start();
    this.getProducts();
    // this.initializeLeadForm();
    this.newLeadForm.get('product')!.setValue(productName);
    // this.ngxLoader.stop();
  }

  public async getProducts() {
    let productsQuery;
    if(!this.authService.isSuperAdmin()){
      productsQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      'classes/Products',
      productsQuery,
    );
    this.productList = resp.results;
  }

  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId },
        isArchived: { $ne : true },
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Spaces', spacesQuery,
    );
    this.spacesList = resp.results;
    if( this.spacesList.length > 0){
      this.selectedSpace = this.spacesList[0].objectId;
      this.getStagesList();
    }
  }

  public async getStagesList(){
    const stageQuery = {
      where: {
      space: { __type: 'Pointer', className: 'Spaces', objectId: this.selectedSpace },
      },
      isArchived:  { $ne: true }
    };
    const resp = await this.dataService.getFromServer(
      'classes/Stages', stageQuery,
    );
    this.stagesList = resp.results;
  }

  isRecurringPrice(): boolean {
    const productId = this.newLeadForm.get('product')!.value;
    if(productId && this.productList){
      const product = this.productList.find(product => product.objectId === productId);
      return product && product.priceType === 'recurring' ? true : false;
    }
    else{
      return false;
    }
  }

  public async createLead() {
    try {
      const dealValue = parseInt(this.newLeadForm.get('dealValue')!.value);
      const description = this.newLeadForm.get('description')!.value;
      const nextActivity = this.newLeadForm.get('nextActivity')!.value;
      const activityNotes = this.newLeadForm.get('activityNotes')!.value;
      let assignTo = '';
      const leadAssignTo = this.newLeadForm.get('leadAssignTo')!.value;
      const stageObjectId = this.newLeadForm.get('stage')!.value;
      const closingDate = this.newLeadForm.get('closingDate')!.value;
      let productId = this.newLeadForm.get('product')!.value;
      let noOfSeats = this.newLeadForm.get('noOfSeats')!.value;
      let estimatedContractLength = this.newLeadForm.get('estimatedContractLength')!.value;
      const category = 'Lead';

      if (this.productID) {
        productId = this.productID;
      }
      if (!dealValue) {
        return this.notifier.notify('error', 'Please Enter Valid Deal Value');
      }
      if (!closingDate) {
        return this.notifier.notify('error', 'Please Enter Valid Closing Date');
      }
      if (!stageObjectId) {
        return this.notifier.notify('error', 'Please Select Stage');
      }   
      if (!description) {
        return this.notifier.notify('error', 'Please Enter Description');
      }
      if(this.isRecurringPrice()){
        if (!noOfSeats) {
          return this.notifier.notify('error', 'Please Enter Number of Subscriptions');
        }
      }
      if(this.isRecurringPrice()){
        if (!estimatedContractLength) {
          return this.notifier.notify('error', 'Please Enter Estimated Contract Length');
        }
      }
      if (this.authService.isAgent()) {
        assignTo = this.authService.getUser().objectId;
      } else {
        assignTo = this.newLeadForm.get('assignTo')!.value;
      }
      this.newleadModal!.hide();
      const leadParams: LeadParams = {
        dealValue,
        description,
        stage: {
          __type: 'Pointer',
          className: 'Stages',
          objectId: stageObjectId,
        },
        product: {
          __type: 'Pointer',
          className: 'Products',
          objectId: productId,
        },
        category,
        assignTo: {
          __type: 'Pointer',
          className: '_User',
          objectId: leadAssignTo ? leadAssignTo : this.currentUser!.objectId,
        },
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: this.contactObjectId,
        },
        activityNotes,
        nextActivity: {
          __type: 'Date',
          iso: new Date(nextActivity),
        },
        creator: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.currentUser!.objectId,
        },
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        closingDate: {
          __type: 'Date',
          iso: new Date(closingDate),
        },
        space:{
          __type: 'Pointer',
          className: 'Spaces',
          objectId: this.selectedSpace,
        }
      };

      if (assignTo) {
        leadParams.agent = {
          __type: 'Pointer',
          className: '_User',
          objectId: assignTo,
        };
      }

      if(noOfSeats){
        leadParams.noOfSeats = noOfSeats
      }
      if(estimatedContractLength){
        leadParams.estimatedContractLength = estimatedContractLength
      }

      await this.leadsService.addLead(leadParams);
      track("NEW_LEAD");
      await this.dataService.updateToServer('classes/Contacts/' + this.contactObjectId, {
        "leadsCount": { "__op": "Increment", "amount": 1 },
        lastActivity: {
          __type: 'Date',
          iso: new Date(),
        },
      });
      this.router.navigate(['/leads/' + this.selectedSpace]);
    }
    catch (e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  //calculating Expected revenue based on no.of subscriptions and estimated contract length
  public onChangeSubsVal(event: Event, prod: any, form: FormGroup) {
    const subsValue = +(event.target as HTMLInputElement).value;
    const eclValue = form.get('estimatedContractLength')!.value;
    this.calcExpRevenue(prod, form, subsValue, eclValue ? eclValue : 1 );
  }

  public onChangeEclVal(event: Event, prod: any, form: FormGroup) {
    const eclValue = +(event.target as HTMLInputElement).value;
    const subsValue = form.get('noOfSeats')!.value;
    this.calcExpRevenue(prod, form, subsValue ? subsValue : 1, eclValue);
  }

  public calcExpRevenue(prod: any, form: FormGroup, subsValue:any, eclValue:any) {
    const selectedProduct = this.productList.find((product) => product.objectId === prod);
    let factor = 1;
    if(selectedProduct.pricePeriod === 'monthly'){
      factor = 12;
    } else if(selectedProduct.pricePeriod === 'weekly'){
      factor = 52;
    } else if(selectedProduct.pricePeriod === 'quarterly'){
      factor = 4;
    } else if(selectedProduct.pricePeriod === 'halfyearly'){
      factor = 2;
    }
    const finalExpRevenue = (subsValue * eclValue * selectedProduct.price * factor);
    form.get('dealValue')!.patchValue(finalExpRevenue);
  }

  public groups () {
    this.router.navigateByUrl("/groups");
  }

  public async contactsInGroup(contact:any){
    const parameters = {
      where: {
        entity: contact.entity,
        contacts: {
          __type: 'Pointer',
            className: 'Contacts',
            objectId: contact.objectId,
        }
      },
    };
    const groupsResp = await this.dataService.getFromServer(
      'classes/Groups',
      parameters,
    );
    return groupsResp.results;
  }

  public loadView(view: string) {
    if (view === "kanban") {
      this.displayKanbanContent = true;
    } else {
      this.displayKanbanContent = false;
    }
    this.loadContactsAndStageData();
  }

  //Fetch Networks Stages List 
  public async getNetworkStagesList() {
    const stageQuery = {
      where: {
        templateType: "network",
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
      },
      order: 'order',
    };
    const resp = await this.dataService.getFromServer(
      "classes/Stages",
      stageQuery
    );
    this.networkStagesList = resp.results
    this.mapStagesToKanbanModel(resp.results);
    console.log(this.networkStagesList);
  }

  mapStagesToKanbanModel(data: any[]) {
   this.kanbanList = data.map((network) => {
      const filteredNetworksByStage = this.contactsPageData.filter((x)=> x.stageId && x.stageId.objectId === network.objectId);
      const setDefaultStageToNetwork = network.order === 1 &&  this.contactsPageData.filter((x) => {
        return !x.stageId;
      }) || [];
      console.log(filteredNetworksByStage);
      console.log(setDefaultStageToNetwork);
      const item: ListParams = {
        name: network.name,
        id: network.objectId,
        order:network.order,
        isNetwork:true,
        data : [...filteredNetworksByStage, ...setDefaultStageToNetwork]
      };
      return item;
    });
  }

  public onStageChange(stage: any){
    //console.log(stage);
    this.updateContactStage(stage.objectId, stage.data.objectId)
  }

  public kanbanViewCardContentClicked(data:any){
    console.log(data.id);
    this.router.navigate(['/contact-details'], {
      state: {
        contactObjectId: data.id,
        isView: true,
      },
    });
  }



  public async updateContactStage(stageId: string, contactId : string){
    const data = {
      stageId: {
      __type: 'Pointer',
      className: 'Stages',
      objectId: stageId,
   },
    }
    try {
       this.dataService.updateToServer("classes/Contacts/" + contactId, data);
    } catch (e) {
      console.log(e.message);
    }
  }

  public declineDeactivationInKanban() {
    this.kanbanDeactivateModal = false;
  }

  public deactivateFromKanban(){
    this.kanbanDeactivateModal = false;
    this.deActivateContact(this.deacivateContactIdInKanban);
  }

  public async deActivateContactFromKanban(contactId:any) {
    this.kanbanDeactivateModal = true;
    this.deacivateContactIdInKanban  = contactId.id;
   // console.log(this.deacivateContactIdInKanban);
  }
  
  public declineactivationInKanban() {
    this.kanbanActivateModal = false;
  }

  public activateFromKanban(){
    this.kanbanActivateModal = false;
    this.activateContact(this.activateContactIdInKanban);
  }

  public async activateContactFromKanban(contactId:any) {
    this.kanbanActivateModal = true;
    this.activateContactIdInKanban  = contactId.id;
   // console.log(this.deacivateContactIdInKanban);
  }

  private initializeEditStageForm() {
    this.editStageForm = this.fb.group({
      name: ['', Validators.required],
      order: ['', Validators.required],
    });
  }

  
    public fetchSelectedStageData(data:any) {
      this.selectedEditStageId = data.id;
      this.editStageModal!.show();
      this.initializeEditStageForm();
      this.editStageForm.patchValue({
        name: data.name || '',
        order: data.order || '0',
        stageId: data.id,
      });
    }

    public updateNetworkStage(){
     console.log(this.selectedEditStageId)
     const data = {
      name: this.editStageForm.get('name')!.value,
      order: this.editStageForm.get('order')!.value,
    };
    try {
       this.dataService.updateToServer("classes/Stages/" + this.selectedEditStageId, data);
       this.notifier.notify('success', 'Stage Edited Successfully!!');
       this.editStageModal!.hide();
       this.loadContactsAndStageData();
    } catch (e) {
      console.log(e.message);
      this.editStageModal!.hide();
    }
  }

  
  // toggleSelectAll(event: Event) {
  //   this.bulkActionChecked = (event.target as HTMLInputElement).checked;
  //   this.dtElement.dtInstance.then((dtInstance: any) => {
  //     this.dtInstance = dtInstance;
  //     if (this.bulkActionChecked) {
  //       this.dtInstance.rows().select(); // Select all rows
  //     } else {
  //       this.dtInstance.rows().deselect();
  //        // Deselect all rows
  //     }
  //   });

  // }

  performBulkAction(data: string) {
   
    try {
      let actionData: any;
      this.dtElement.dtInstance.then(async (dtInstance: any) => {
       const  selectedData = dtInstance.rows({ selected: true }).data().toArray();
       const  selectedIds = selectedData.map((contact: Contacts) => contact.objectId);

        if (selectedIds.length === 0) {
          this.notifier.notify('error', 'No rows selected');
          // alert('No rows selected');
          return;
        }

        if (data === 'active') {
          this.bulkArchiveModal = false;
          actionData = { "key": "isActive", "value": false };
        } else {
          this.bulkUnarchiveModal = false;
          actionData = { "key": "isActive", "value": true };
        }
       // this.bulkActionChecked = true;

        // Perform bulk action here with selectedIds
        console.log('Selected IDs:', selectedIds);
        const response = await this.dataService.postToServer(
          "functions/bulkActions",
          {
            objectIdArray: selectedIds,
            className: "Contacts",
            action: actionData
          }
        );
        if (response.result == true) {
          dtInstance.rows({ selected: true }).remove().draw();
          //this.bulkActionChecked = false;

        }
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  public declineBulkArchive() {
    this.bulkArchiveModal = false;
  }

  public declineBulkUnarchive() {
    this.bulkUnarchiveModal = false;
  }

  public openBulkActionModal(data:string){
    if (data === 'active') {
      this.bulkArchiveModal = true;
    } else {
      this.bulkUnarchiveModal = true;
    }
  }




}
